import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class PricePolicyActions {
   getList = async (query) => {
      const params = {
        page: query?.page || 1,
        limit: query?.limit || 20,
        order_by: query?.orderBy || 'created_at',
        order_dir: query?.orderDir || 'desc'
      }
  
      return await apiRequestWrapper(
        ApiConnector.connector.get(`/v1/admin/price-policies`, {
          params
        })
      )
    }
    create = async (data) => {
      return await apiRequestWrapper(
        ApiConnector.connector.post(
          'v1/admin/price-policies', data
        ),
        201
      )
    }
    update = async (id, data) => {
      return await apiRequestWrapper(
        ApiConnector.connector.put(
          `/v1/admin/price-policies/${id}`,
          data
        ),
        200
      )
    }
    delete = async (id) => {
      return await apiRequestWrapper(
        ApiConnector.connector.delete(`/v1/admin/price-policies/${id}`),
        204
      )
    }
}
export default new PricePolicyActions()