import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class CategoriesActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
    }

    if (query?.orderBy) {
      params.order_by = query.orderBy
    }

    if (query?.orderDir) {
      params.order_dir = query.orderDir
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/categories`, {
        params,
      }),
    )
  }

  getOne = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/categories/${id}`),
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/categories`, data),
      201,
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/categories/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/categories/${id}`),
      204,
    )
  }
}

export default new CategoriesActions()
