import store from '@/store'

class WsChatActions {
  socket

  constructor() {
    this.socket = null
  }

  connect = () => {
    if (store) {
      return new Promise(function (resolve, reject) {
        const server = new WebSocket(
          process.env.VUE_APP_WS_URL || 'ws://localhost:8081/ws/admin'
        )
        server.onopen = function () {
          store.commit('setSocketStatus', true)
          resolve(server)
        }
        server.onerror = function (err) {
          store.commit('setSocketStatus', false)
          reject(err)
        }
      })
    }
  }

  open = async () => {
    try {
      if (store) {
        this.socket = await this.connect()

        this.socket.onmessage = function (e) {
          const messages = JSON.parse(e.data)
          if (messages.type === 'new_message') {
            store.commit('addMessage', messages.payload)
            store.dispatch('getChats', messages.payload)
          }
          if (messages.type === 'updated_messages') {
            messages.payload.messages.forEach((item) => {
              store.commit('addMessage', item)
            })
          }
        }

        this.socket.onclose = function () {
          store.commit('setSocketStatus', false)
        }
      }
    } catch {
      return new Error('WebSocket error, try again later')
    }
  }

  send = (data) => {
    try {
      this.socket.send(JSON.stringify(data))
      return null
    } catch (e) {
      return new Error('WebSocket error, try again later')
    }
  }

  sendMessage = (data) => {
    try {
      const event = { type: 'send_message', payload: data }
      this.send(event)
      return null
    } catch (e) {
      return new Error('WebSocket error, try again later')
    }
  }

  readMessages = (data) => {
    try {
      const event = { type: 'read_messages', payload: data }
      this.send(event)
      return null
    } catch (e) {
      return new Error('WebSocket error, try again later')
    }
  }

  close = () => {
    try {
      this.socket.close(1000, 'Close WS')
      return null
    } catch (e) {
      return new Error('WebSocket error, try again later')
    }
  }
}
export default new WsChatActions()
