import { HTTP_STATUSES } from '@/constants/common'
import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class AssortmentsActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.orderBy || 'category_id,order_field',
      order_dir: query?.orderDir || 'desc',
      search: query?.search || '',
      category_id: query?.categoryId || '',
      is_active: query?.isActive || '',
      is_promotional: query?.isPromotional || '',
      is_in_main_catalog: query?.isInCatalog || '',
    }

    if (query?.promocodeId) {
      params.promocode_id = query.promocodeId
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/assortments`, {
        params,
      }),
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/assortments/${id}`),
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post('/v2/admin/assortments', data),
      HTTP_STATUSES.CREATED,
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/assortments/${id}`, data),
    )
  }

  updateAssortments = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/assortments`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/assortments/${id}`),
      HTTP_STATUSES.NO_CONTENT,
    )
  }

  getPdfCatalog = async (assortmentId) => {
    const result = {
      value: false,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.get(
        '/v2/admin/assortments/' + assortmentId + '/pdf',
        { responseType: 'blob' },
      )
      if (response.status === 200) {
        result.value = response.data
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again later')
    }

    return result
  }
}

export default new AssortmentsActions()
