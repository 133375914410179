import { HTTP_STATUSES } from '@/constants/common'
import { apiRequestWrapper } from '@/helpers/delivery'
import ApiConnector from '../connector'

class NotesActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.orderBy || 'created_at',
      order_dir: query?.orderDir || 'desc',
    }

    if (query.preloadDealers) {
      params.preload_dealers = query.preloadDealers
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/manager-notes`, {
        params,
      }),
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/manager-notes/${id}`),
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v1/admin/manager-notes/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v1/admin/manager-notes/${id}`),
      HTTP_STATUSES.NO_CONTENT,
    )
  }
}

export default new NotesActions()
