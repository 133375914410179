import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class LandingActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      search: query?.search || '',
      order_by: query.orderBy ?? 'name',
      order_dir: query.orderDir ?? 'asc',
    }
    return await apiRequestWrapper(
      ApiConnector.connector.get('/v2/admin/landings', { params })
    )
  }

  getLandingById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get('/v2/admin/landings/' + id)
    )
  }

  createLanding = async (landing) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post('/v2/admin/landings', landing),
      201
    )
  }

  updateLanding = async (id, landing) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put('/v2/admin/landings/' + id, landing)
    )
  }

  deleteLandingById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete('/v2/admin/landings/' + id),
      204
    )
  }
}

export default new LandingActions()
