import { HTTP_STATUSES } from '@/constants/common'
import { apiRequestWrapper } from '@/helpers/delivery'
import ApiConnector from '../connector'

class LoyaltyProgramActions {
  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v1/admin/loyalty-level`, data),
      HTTP_STATUSES.CREATED,
    )
  }

  getList = async (query) => {
    const params = {
      order_dir: query.orderDir ?? 'desc',
      limit: query.limit ?? 0,
      page: query.page ?? 0,
    }

    if (query.status) {
      params.status = query.status ?? ''
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/loyalty-level`, { params }),
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/loyalty-level/${id}`),
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v1/admin/loyalty-level/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v1/admin/loyalty-level/${id}`),
      HTTP_STATUSES.NO_CONTENT,
    )
  }
}

export default new LoyaltyProgramActions()
