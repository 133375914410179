const getCatalogParams = (query) => {
  const params = {
    page: query.page || 1,
    limit: query.limit
  }

  if (query.price && query.price.min !== null)
    params.min_price = query.price.min

  if (query.price && query.price.max !== null)
    params.max_price = query.price.max

  if (query.withDiscountPrice)
    params.with_discount_price = query.withDiscountPrice

  if (query.isAvailable) params.is_available = query.isAvailable

  if (query.groups && query.groups.length)
    params.groups = query.groups.join(',')

  if (query.brands && query.brands.length)
    params.brands = query.brands.join(',')

  if (query.supplierId !== null) params.supplier_id = query.supplierId

  if (query.exceptDealerCatalogId !== null)
    params.except_dealer_catalog_id = query.exceptDealerCatalogId

  if (query.search) {
    params.search = query.search
  }

  if (query.isPortfolioAddwine) {
    params.is_portfolio_addwine = query.isPortfolioAddwine
  }

  return params
}

export { getCatalogParams }
