import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class RankedAttributesActions {
  getList = async (query) => {

    const params = {
      order_by: query?.order_by || "order_field",
      order_dir: query?.order_dir || "asc"
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/ranked-attributes`, {
        params
      })
    )
  }

  update = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/ranked-attributes`, data), 
      204
    )
  }
}

export default new RankedAttributesActions()
