import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class DeliverySettings {
  get = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/delivery-settings`)
    )
  }

  save = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/delivery-settings`, data)
    )
  }
}

export default new DeliverySettings()
