import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class BonusTransactionsActions {
  getList = async (id, query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.orderBy || 'created_at',
      order_dir: query?.orderDir || 'desc'
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/user-accounts/${id}/bonus-transactions`, {
        params
      })
    )
  }

  getCount = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/user-accounts/${id}/bonus-transactions/count`)
    )
  }
}

export default new BonusTransactionsActions()
