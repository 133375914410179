import connector from './connector'

import AuthSellersActions from './AuthSellersActions'
import BonusProgramActions from './BonusProgramActions'
import BrandsActions from './BrandsActions'
import CatalogsActions from './CatalogsActions'
import ChatsActions from './ChatsActions'
import ClientRequesitesActions from './ClientRequesitesActions'
import DaDataActions from './DaDataActions'
import DealersActions from './DealersActions'
import DownloadsActions from './DownloadsActions'
import ImagesActions from './ImagesActions'
import InstructionalVideosActions from './InstructionalVideosActions'
import LoyaltyProgramActions from './LoyaltyProgramActions'
import MailActions from './MailActions'
import ManagersActions from './ManagersActions'
import MarketingNotificationActions from './MarketingNotificationActions'
import MoySkladActions from './MoySkladActions'
import NotesActions from './NotesActions'
import OffersActions from './OffersActions'
import OrdersActions from './OrdersActions'
import PricePolicyActions from './PricePolicyActions'
import ProductsActions from './ProductsActions'
import SellersSettingsActions from './SellersSettingsActions'
import SubscriptionsActions from './SubscriptionsActions'
import SuppliersActions from './SuppliersActions'
import WsChatActions from './WsChatActions'

class AddwineCore {
  connector = connector
  MailActions = MailActions
  ImagesActions = ImagesActions
  ClientRequesitesActions = ClientRequesitesActions
  OrdersActions = OrdersActions
  DealersActions = DealersActions
  DownloadsActions = DownloadsActions
  ProductsActions = ProductsActions
  OffersActions = OffersActions
  ManagersActions = ManagersActions
  AuthSellersActions = AuthSellersActions
  SellersSettingsActions = SellersSettingsActions
  CatalogsActions = CatalogsActions
  ChatsActions = ChatsActions
  WsChatActions = WsChatActions
  MarketingNotificationActions = MarketingNotificationActions
  SuppliersActions = SuppliersActions
  InstructionalVideosActions = InstructionalVideosActions
  SubscriptionActions = SubscriptionsActions
  PricePolicyActions = PricePolicyActions
  MoySkladActions = MoySkladActions
  DaDataActions = DaDataActions
  BrandsActions = BrandsActions
  BonusProgramActions = BonusProgramActions
  LoyaltyProgramActions = LoyaltyProgramActions
  NotesActions = NotesActions
}

export default new AddwineCore()
