import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'
import { SORTING_TYPES, HTTP_STATUSES } from '@/constants/common'

class OrdersActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.orderBy ?? 'name',
      order_dir: query?.orderDir ?? SORTING_TYPES.ASC,
    }

    if (query?.search) {
      params.search = query.search
    }

    if (query?.startAt) {
      params.reservation_start_at = query.startAt
    }
    if (query?.finishAt) {
      params.reservation_end_at = query.finishAt
    }

    if (query?.status) {
      params.status = query.status
    }
    if (query?.paymentType) {
      params.payment_type = query.paymentType
    }
    if (query?.deliveryType) {
      params.delivery_type = query.deliveryType
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/orders`, {
        params,
      }),
    )
  }
  getOne = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/orders/${id}`),
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/orders`, data),
      HTTP_STATUSES.CREATED,
    )
  }

  update = async (data, id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/orders/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/orders/${id}`),
      HTTP_STATUSES.NO_CONTENT,
    )
  }
}

export default new OrdersActions()
