import delivery from '@/delivery'
import router from '@/router'
import Cookies from 'js-cookie'
import { ACCESS_MAP } from '../../constants/routing'
import { ADDITIONAL_ROLES, DEFAULT_ROLES, ROLES } from '../../constants/roles'
import { PROJECTS } from '@/constants/projects'

export default {
  state: () => ({
    accessToken: null,
    refreshToken: null,
    role: null,
    additionalRoles: null,
    manager: {
      id: null,
      isAdmin: false,
      landingsAccess: false,
      sellersAccess: false,
      addwineAccess: false,
      coordinatorAccess: false,
    },
  }),

  getters: {
    filterAccessSidebarList(store) {
      return (routes) => {
        const list = Object.keys(routes)
        return (
          list?.filter(
            (e) =>
              routes[e] &&
              ((!ACCESS_MAP[routes[e]] &&
                DEFAULT_ROLES?.includes(store.role)) ||
                ACCESS_MAP[routes[e]]?.includes(store.role)),
          ) ?? []
        )
      }
    },
    filterAccessMenuList(store) {
      return (list) =>
        list?.filter(
          (e) =>
            (!ACCESS_MAP[e.link] && DEFAULT_ROLES?.includes(store.role)) ||
            ACCESS_MAP[e.link]?.includes(store.role),
        ) ?? []
    },
    isAuth(state) {
      return !!state.accessToken
    },
    isAdminOrOwner(state) {
      return state.role === ROLES.ADMIN || state.role === ROLES.OWNER
    },
    isOwner(state) {
      return state.role === ROLES.OWNER
    },
    isRemoteManager(state) {
      return state.role === ROLES.REMOTE_MANAGER
    },
    landingsAccess(state) {
      return state.manager.landingsAccess
    },
    sellersAccess(state) {
      return state.manager.sellersAccess
    },
    addwineAccess(state) {
      return state.manager.addwineAccess
    },
    addeventAccess(state) {
      return state.manager.addeventAccess
    },
    coordinatorAccess(state) {
      return state.manager.coordinatorAccess
    },
  },

  mutations: {
    setTokens(state, data) {
      state.accessToken = data.accessToken
      Cookies.set(
        'authentication-cookie',
        JSON.stringify({
          auth: { accessToken: data.accessToken },
        }),
        { expires: 1, secure: false },
      )
      if (data.refreshToken) {
        state.refreshToken = data.refreshToken
      }
    },
    setRoles(state, data) {
      state.role = data.role
      state.additionalRoles = data.additionalRoles
      Cookies.set(
        'access-data',
        JSON.stringify({
          auth: {
            roles: data.role,
            additionalRoles: data.additionalRoles,
          },
        }),
        { expires: 1, secure: false },
      )
    },
    logout(state) {
      state.accessToken = null
      state.refreshToken = null
      state.role = null
      state.additionalRoles = null
    },
    setManager(state, data) {
      state.manager = data
      state.manager.landingsAccess = data.projectPermission.includes(
        PROJECTS.ADDLANDINGS,
      )
      state.manager.sellersAccess = data.projectPermission.includes(
        PROJECTS.ADDSELLERS,
      )
      state.manager.addwineAccess = data.projectPermission.includes(
        PROJECTS.ADDWINE,
      )
      state.manager.addeventAccess = data.projectPermission.includes(
        PROJECTS.ADDEVENTS,
      )
      state.manager.coordinatorAccess =
        data.additionalRoles?.includes(ADDITIONAL_ROLES.COORDINATOR) ?? false
    },
  },

  actions: {
    async login({ commit, dispatch }, data) {
      const { value, error } =
        await delivery.AddwineCore.AuthSellersActions.signIn(data)
      if (!error) {
        commit('setTokens', value)
        dispatch('getSelfManager')
      }

      return { value, error }
    },
    async refresh({ state, commit, dispatch }) {
      const { value, error } =
        await delivery.AddwineCore.AuthSellersActions.refresh(
          state.refreshToken,
        )
      if (!error) {
        commit('setTokens', value)
        dispatch('getSelfManager')
      }

      return { value, error }
    },
    async getSelfManager({ commit, state }) {
      if (state.refreshToken) {
        const { value, error } =
          await delivery.AddwineCore.AuthSellersActions.self()
        if (!error) {
          commit('setManager', value)
          commit('setRoles', value)
        }

        return { value, error }
      }
    },

    logout({ commit }) {
      commit('logout')
      Cookies.set(
        'authentication-cookie',
        JSON.stringify({
          auth: { accessToken: null },
        }),
        { expires: 1, secure: false },
      )
      Cookies.set(
        'access-data',
        JSON.stringify({
          access: {
            roles: null,
            additionalRoles: null,
          },
        }),
        { expires: 1, secure: false },
      )
      router.push('/sign-in')
    },

    async request(ctx, config) {
      return await delivery.AddwineCore.AuthSellersActions.baseRequest(config)
    },
  },
}
