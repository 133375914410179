import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class MoySkladActions {
    synchronize = async () => {
      return await apiRequestWrapper(
        ApiConnector.connector.post(`/v1/admin/moysklad/products/sync`),
        202
      )
    }
}
export default new MoySkladActions()