export default {
  bind(el, binding) {
    // Get the scroll box defined by element UI
    const SELECTWRAP_DOM = el.querySelector(
      '.el-select-dropdown .el-select-dropdown__wrap'
    )
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      /**
       * scrollHeight Get element content height (read only)
       * scrollTop Gets or sets the offset value of an element. It is often used to calculate the position of a scroll bar. When an element's container does not produce a scroll bar in a vertical direction, its scrollTop value defaults to 0
       * clientHeight Read the visible height of the element (read only)
       * If the element scrolls to the end, the following equation returns true, otherwise false:
       * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
       */
      const condition =
        this.scrollHeight - this.scrollTop <= this.clientHeight + 1
      if (condition) {
        binding.value()
      }
    })
  },
  name: 'el-select-loadmore'
}
