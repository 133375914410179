import ApiConnector from '../connector'

class MarketingNotificationActions {
  getList = async (
    expired,
    page = 1,
    limit = 20,
    orderBy = null,
    orderDir = null
  ) => {
    const result = {
      value: false,
      error: null
    }

    const params = { page, limit }

    if (expired !== null) {
      params.expired = expired
    }
    if (orderBy !== null) {
      params.order_by = orderBy
    }
    if (orderDir !== null) {
      params.order_dir = orderDir
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/marketing-notifications`,
        { params }
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  getById = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/marketing-notifications/${id}`
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  create = async (data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.post(
        '/v1/admin/marketing-notifications',
        data
      )

      if (response.status === 201) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again later')
    }

    return result
  }
  update = async (id, data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.put(
        `/v1/admin/marketing-notifications/${id}`,
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  delete = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v1/admin/marketing-notifications/${id}`
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  updateOrders = async (data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.put(
        '/v1/admin/marketing-notifications/order',
        data
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new MarketingNotificationActions()
