import ApiConnector from '../connector'
import { transformFiltersToQuery } from '@/helpers/addwine/products'
import { apiRequestWrapper } from '@/helpers/delivery'

class ProductsActions {
  getList = async (query) => {
    const result = {
      value: false,
      error: null,
    }

    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_dir: query?.orderDir,
      order_by: query?.orderBy,
    }

    if (query?.search) {
      params.search = query.search
    }

    if (typeof query?.isBundle === 'boolean') {
      params.is_bundle = query.isBundle
    }

    try {
      const response = await ApiConnector.connector.get(`/v2/admin/products`, {
        params,
      })

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  getById = async (id) => {
    const result = {
      value: false,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v2/admin/products/${id}`,
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  create = async (data) => {
    const result = {
      value: false,
      error: null,
    }
    try {
      const response = await ApiConnector.connector.post(
        '/v2/admin/products',
        data,
      )

      if (response.status === 201) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again later')
    }

    return result
  }

  update = async (id, data) => {
    const result = {
      value: false,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.put(
        `/v2/admin/products/${id}`,
        data,
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  delete = async (query) => {
    const result = {
      error: null,
    }

    if (!query.ids || !query.ids.length) {
      return (result.error = new Error('Empty Ids'))
    }

    const params = {
      ids: query.ids.join(','),
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v2/admin/products`,
        { params },
      )

      if (response.status !== 204) {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  deleteByFilters = async (filters) => {
    const result = {
      error: null,
    }

    try {
      const params = transformFiltersToQuery(filters)

      const response = await ApiConnector.connector.delete(
        `/v2/admin/products/by-filters`,
        { params },
      )

      if (response.status !== 204) {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (err) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  updateFlagsByFilters = async (filters, flags) => {
    const result = {
      error: null,
    }

    try {
      const params = transformFiltersToQuery(filters)

      const response = await ApiConnector.connector.patch(
        `/v2/admin/products/flags/by-filters`,
        flags,
        { params },
      )

      if (response.status !== 204) {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (err) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  updateFlags = async (query) => {
    const result = {
      error: null,
    }
    if (!query.ids || !query.ids.length) {
      return (result.error = new Error('Empty Ids'))
    }

    const params = {
      ids: query.ids.join(','),
    }

    try {
      const response = await ApiConnector.connector.patch(
        `/v2/admin/products/flags`,
        query.flags,
        { params },
      )

      if (response.status !== 204) {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (err) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  getProductStocks = async (id) => {
    const result = {
      value: null,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v2/admin/products/${id}/stocks`,
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (err) {
      result.error = new Error('Network error, try again late')
    }
    return result
  }

  updateProductsSEOsByTemplate = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.patch(`/v2/admin/products/seos/by-template`),
      202,
    )
  }

  getProductsSuppliers = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.orderBy,
      order_dir: query?.orderDir,
      search: query?.search || '',
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/products/suppliers`, {
        params,
      }),
    )
  }
}
export default new ProductsActions()
