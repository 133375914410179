import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class PartnerCategoriesActions {

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post('/v2/admin/partner-categories', data),
      201
    )
  }

  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.orderBy || 'order_field',
      order_dir: query?.orderDir || 'desc',
      search: query?.search || ''
    }
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/partner-categories`, {
        params
      })
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/partner-categories/${id}`)
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/partner-categories/${id}`, data)
    )
  }

  delete = async (id) => {
    `/v2/admin/vacancies/${id}`
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/partner-categories/${id}`),
      204
    )
  }
}

export default new PartnerCategoriesActions()
