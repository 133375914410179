import ApiConnector from '../connector'

class DownloadsActions {
  getList = async () => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        '/v1/admin/download-groups'
      )
      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  getById = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/download-groups/${id}`
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  createGroup = async (data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.post(
        '/v1/admin/download-groups',
        data
      )

      if (response.status === 201) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  changeGroupName = async (id, data) => {
    const result = {
      value: false,
      error: null
    }
    try {
      const response = await ApiConnector.connector.put(
        `/v1/admin/download-groups/${id}`,
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  deleteGroup = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v1/admin/download-groups/${id}`
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  updateOrders = async (data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.patch(
        `/v1/admin/download-groups/order`,
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  creatFile = async (data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.post(
        '/v1/admin/downloads',
        data
      )

      if (response.status === 201) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      if (
        e.response.status === 400 &&
        e.response.data.errors[0].includes('UNSUPPORTED MEDIA TYPE')
      ) {
        result.error = `Недопустимый тип файла.\nДопустимые типы: ${
          e.response.data.errors[0].split(':')[1]
        }`
      } else {
        result.error = new Error('Network error, try again late')
      }
    }

    return result
  }
  getFilesByGroupId = async (query) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/downloads?group_id=${query.group_id}`
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }
    return result
  }
  deleteFile = async (id) => {
    const result = {
      value: false,
      error: null
    }
    try {
      const response = await ApiConnector.connector.delete(
        `/v1/admin/downloads/${id}`
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  changeFileName = async (id, data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.put(
        `/v1/admin/downloads/${id}`,
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new DownloadsActions()
