export const PROJECTS = {
  ADDWINE: 'addwine',
  ADDLANDINGS: 'landings',
  ADDSELLERS: 'sellers',
  ADDEVENTS: 'addevents',
}

export const PROJECTS_TRANSLATED = {
  ADDWINE: 'Addwine',
  ADDLANDINGS: 'Landings',
  ADDSELLERS: 'Sellers',
  ADDEVENTS: 'Addevent',
}
