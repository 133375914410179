import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class PromoActions {
  check = async (data) => {
  

    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/promocodes/products`, data),
    )
  }
}

export default new PromoActions()
