<template>
  <div :class="$style.authWrapper">
    <div :class="$style.container">
      <transition name="page">
        <Sidebar />
      </transition>
      <slot />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/moleculs/Sidebar.vue'

export default {
  name: 'AuthLayout',
  components: {
    Sidebar,
  },
}
</script>
<style lang="scss" module>
.authWrapper {
  .container {
    display: flex;
    height: 100%;
    min-height: 100vh;
    > div:last-of-type {
      width: calc(100% - 16rem);
    }
  }
}
</style>
