import isAuth from '../middleware/isAuth'
import hasLandingAccess from '../middleware/hasLandingAccess'

const metaData = {
  middleware: [isAuth, hasLandingAccess],
  layout: 'auth-layout'
}

export default {
  path: '/landings',
  component: () => import('@/views/landings/Index.vue'),
  children: [
    {
      path: '',
      name: 'landingList',
      component: () => import('@/views/landings/LandingList.vue'),
      meta: metaData
    },
    {
      path: 'create',
      name: 'landingCreate',
      component: () => import('@/views/landings/LandingConstructor.vue'),
      meta: metaData
    },
    {
      path: ':id',
      name: 'landingEdit',
      component: () => import('@/views/landings/LandingConstructor.vue'),
      meta: metaData
    }
  ]
}
