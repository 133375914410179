import ApiConnector from '../connector'

class SeoTextsActions {
  getList = async (query) => {
    const result = {
      value: false,
      error: null
    }

    const params = {
      page: query?.page ?? 1,
      limit: query?.limit ?? 20,
      search: query?.search ?? null,
      is_linked: query?.isLinked ?? null
    }

    try {
      const response = await ApiConnector.connector.get(`/v2/admin/seo-texts`, {
        params
      })

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  create = async (data) => {
    const result = {
      value: false,
      error: null
    }
    try {
      const response = await ApiConnector.connector.post(
        '/v2/admin/seo-texts/',
        data
      )
      if (response.status === 201) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again later')
    }
    return result
  }

  getByIds = async (ids) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v2/admin/seo-texts/${ids}`
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  update = async (id, data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.put(
        `/v2/admin/seo-texts/${id}`,
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  delete = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v2/admin/seo-texts/${id}`
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new SeoTextsActions()
