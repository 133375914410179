import { getCatalogParams } from '@/helpers/delivery/sellers'
import { camelToSnakeCase } from '@/helpers/convertString'

import ApiConnector from '../connector'

class CatalogsActions {
  getList = async (query) => {
    const result = {
      value: false,
      error: null
    }
    const params = {
      page: query.page || 1,
      limit: query.limit || 10
    }
    if (query.orderBy) {
      params.order_by = camelToSnakeCase(query.orderBy)
    }
    if (query.orderDir) {
      params.order_dir = query.orderDir
    }
    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/dealer-catalogs`,
        {
          params
        }
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  getById = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/dealer-catalogs/${id}`
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  create = async (data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.post(
        '/v1/admin/dealer-catalogs',
        data
      )

      if (response.status === 201) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again later')
    }

    return result
  }
  delete = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v1/admin/dealer-catalogs/${id}`
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  update = async (id, data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.patch(
        `/v1/admin/dealer-catalogs/${id}`,
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  getProductsFilterOptions = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/dealer-catalogs/${id}/products/filter-options`
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  getProductList = async (id, query) => {
    const result = {
      value: false,
      error: null
    }

    const params = getCatalogParams(query)

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/dealer-catalogs/${id}/products`,
        { params }
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  addProduct = async (catalogId, productId) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.put(
        `/v1/admin/dealer-catalogs/${catalogId}/products/${productId}`
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      if (
        e.response.status === 400 &&
        e.response.data.errors[0] ===
          'PRODUCT ALREADY EXIST IN RECEIVED DEALER CATALOG'
      ) {
        result.error = 'PRODUCT ALREADY EXIST IN RECEIVED DEALER CATALOG'
      } else {
        result.error = new Error('Network error, try again late')
      }
    }

    return result
  }
  deleteProduct = async (catalogId, productId) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v1/admin/dealer-catalogs/${catalogId}/products/${productId}`
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  addFilteredProducts = async (id, query) => {
    const result = {
      value: false,
      error: null
    }

    const params = getCatalogParams(query)

    try {
      const response = await ApiConnector.connector.put(
        `/v1/admin/dealer-catalogs/${id}/products`,
        null,
        { params }
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  deleteFilteredProducts = async (id, query) => {
    const result = {
      value: false,
      error: null
    }

    const params = getCatalogParams(query)

    try {
      const response = await ApiConnector.connector.delete(
        `/v1/admin/dealer-catalogs/${id}/products`,
        { params }
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  getSearchList = async (query) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/dealer-catalogs`,
        {
          params: { limit: query.limit, search: query.search, page: query.page }
        }
      )
      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new CatalogsActions()
