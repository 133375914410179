import { PROJECTS } from '@/constants/projects'
import { ROLES } from '../roles'

const ADDWINE_URL = process.env.VUE_APP_ADW_URL
const ADDSELLERS_URL = process.env.VUE_APP_ADS_URL
const ADDLANDINGS_URL = process.env.VUE_APP_LANDING_URL
const ADDEVENT_URL = process.env.VUE_APP_ADDEVENT_URL

export const LANDING_ROUTES = {
  LIST: '/landings',
  CREATE: '/landings/create',
  VIEW: '/landings/:id',
}

export const PROFILE_ROUTES = {
  INDEX: '/profile',
}

export const MANAGERS_ROUTES = {
  PATH: 'managers',
  INDEX: '/managers',
  UPDATE: '/managers/edit/:id',
  CREATE: '/managers/create',
}

export const LANDING_ROUTES_LABELS = {
  [LANDING_ROUTES.LIST]: 'Список лендингов',
  [LANDING_ROUTES.CREATE]: 'Создать лендинг',
}

export const ADDWINE_ROUTES = {
  BRANDS: {
    LIST: 'brands/',
    BRAND: 'brands/:id',
  },
  ARTICLES: {
    LIST: 'articles/',
    ARTICLE: 'articles/:categorySlug/:slug',
    CATEGORY: 'articles/:id',
  },
  CATALOG: {
    INDEX: 'catalogue',
    RANGES: 'catalogue/ranges/:id',
    PRODUCT: 'catalogue/:id',
    CATEGORY: 'catalogue/category/:id',
  },
}

export const ADDWINE_ADMIN_ROUTES = {
  INDEX: '/addwine',
  INDEX_PATH: 'addwine',
  CATALOG_INDEX: '/addwine/catalog',
  PAYMENTS_INDEX: '/addwine/payments',
  ORDERS_INDEX: '/addwine/orders',
  BLOG_INDEX: '/addwine/blog',
  PROGRAM_LOYALTY_INDEX: '/addwine/loyalty-program',
  PROMOTIONS_INDEX: '/addwine/promotions',
  CONTENT_INDEX: '/addwine/content',
  USERS_INDEX: '/addwine/users',
  SUBSCRIPTIONS_INDEX: '/addwine/subscriptions',
  ANALYTICS_INDEX: '/addwine/analytics',
  SETTINGS_INDEX: '/addwine/settings',
  FEEDBACKS_INDEX: '/addwine/feedbacks',
  SETTINGS_PDF_INDEX: '/addwine/settings-pdf',
  CREATE_PATH: 'create',
  UPDATE_PATH: ':id',
  CATALOG: {
    PATH: 'catalog',
    INDEX: '/catalog',
    GROUPS: {
      PATH: 'groups',
      LIST: '/addwine/catalog/groups',
      GROUP: '/addwine/catalog/groups/:id',
      CREATE: '/addwine/catalog/groups/create',
    },
    BRANDS: {
      PATH: 'brands',
      LIST: '/addwine/catalog/brands',
      BRAND: '/addwine/catalog/brands/:id',
      CREATE: '/addwine/catalog/brands/create',
    },
    ASSORTMENTS: {
      PATH: 'assortments',
      LIST: '/addwine/catalog/assortments',
      ASSORTMENT: '/addwine/catalog/assortments/:id',
      CREATE: '/addwine/catalog/assortments/create',
      CATALOG: '/addwine/catalog/assortments/:id/catalog',
    },
    CATEGORIES: {
      PATH: 'categories',
      LIST: '/addwine/catalog/categories',
      CATEGORY: '/addwine/catalog/categories/:id',
      CATALOG: '/addwine/catalog/categories/:id/catalog',
    },
    ATTRIBUTES: {
      LIST: '/addwine/catalog/attributes',
    },
    PRODUCTS: {
      PATH: 'products',
      LIST: '/addwine/catalog/products',
      PRODUCT: '/addwine/catalog/products/:id',
      PREORDER: '/addwine/catalog/products/:id/subscriptions?type=preorder',
      CREATE: '/addwine/catalog/products/create',
    },
  },
  PAYMENTS: {
    PATH: 'payments',
    INDEX: '/payments',
    LOCATIONS_DELIVERY: {
      path: 'locations-delivery',
      INDEX: '/addwine/payments/locations-delivery',
    },
    PAYMENT_TYPES: {
      INDEX: '/addwine/payments/payment-types',
    },
    ORDER_STATUSES: {
      INDEX: '/addwine/payments/order-statuses',
    },
    PAYMENT_STATUSES: {
      INDEX: '/addwine/payments/payment-statuses',
    },
  },
  ORDERS: {
    PATH: 'orders',
    INDEX: '/orders',
    LIST: '/addwine/orders',
  },
  BLOG: {
    PATH: 'blog',
    INDEX: '/blog',
    CATEGORIES_INDEX: '/addwine/blog/categories',
    ARTICLES_INDEX: '/addwine/blog/articles',
    CATEGORIES: {
      LIST: '/addwine/blog/categories',
      CATEGORY: '/addwine/blog/categories/:id',
      CREATE: '/addwine/blog/categories/create',
    },
    ARTICLES: {
      LIST: '/addwine/blog/articles',
      ARTICLE: '/addwine/blog/articles/:id',
      CREATE: '/addwine/blog/articles/create',
    },
  },
  PROMOTIONS: {
    PATH: 'promotions',
    INDEX: '/promotions',
    PROMOS: {
      INDEX: '/addwine/promotions/promos',
      AD_PROMOS: {
        INDEX: '/addwine/promotions/promos/ad-promos',
        VIEW: '/addwine/promotions/promos/ad-promos/:id',
      },
      GIFT_PROMOS: {
        INDEX: '/addwine/promotions/promos/gift-promos',
        VIEW: '/addwine/promotions/promos/gift-promos/:id',
      },
    },
    DISCOUNT: {
      INDEX: '/addwine/promotions/promo-articles',
    },
  },
  CONTENT: {
    PATH: 'content',
    INDEX: '/content',
    PAGES: {
      PATH: 'pages',
      INDEX: '/addwine/content/pages',
      MAIN: {
        INDEX: '/addwine/content/pages/main',
      },
      CONTACTS: {
        PATH: 'contacts',
        INDEX: '/addwine/content/pages/contacts',
      },
      ABOUT: {
        PATH: 'about',
        INDEX: '/addwine/content/pages/about',
      },
      ARTICLES: {
        PATH: 'articles',
        INDEX: '/addwine/content/pages/articles',
      },
      CATALOGUE: {
        PATH: 'catalogue',
        INDEX: '/addwine/content/pages/catalogue',
      },
      PRIVATE_POLICY: {
        PATH: 'privacy-policy',
        INDEX: '/addwine/content/pages/privacy-policy',
      },
      COMMON: {
        PATH: 'common',
        INDEX: '/addwine/content/pages/common',
      },
      PROMOTIONS: {
        PATH: 'promotions',
        INDEX: '/addwine/content/pages/promotions',
      },
      DELIVERY: {
        PATH: 'delivery',
        INDEX: '/addwine/content/pages/delivery',
      },
      WARRANTY: {
        PATH: 'warranty',
        INDEX: '/addwine/content/pages/warranty',
      },
      PURCHASE_CANCELLATION: {
        PATH: 'purchase-cancellation',
        INDEX: '/addwine/content/pages/purchase-cancellation',
      },
      BONUS_INFO: {
        PATH: 'bonus-info',
        INDEX: '/addwine/content/pages/bonus-info',
      },
      LOYALTY_PROGRAM_INFO: {
        PATH: 'loyalty-program-info',
        INDEX: '/addwine/content/pages/loyalty-program-info',
      },
      PURCHASE_RETURNS: {
        PATH: 'purchase-returns',
        INDEX: '/addwine/content/pages/purchase-returns',
      },
      BECOME_A_SUPPLIER: {
        PATH: 'become-a-supplier',
        INDEX: '/addwine/content/pages/become-a-supplier',
      },
      DOCUMENTS: {
        PATH: 'documents',
        INDEX: '/addwine/content/pages/documents',
      },
      ORDER_INFO: {
        PATH: 'order-info',
        INDEX: '/addwine/content/pages/order-info',
      },
      CLIENTS: {
        PATH: 'clients',
        INDEX: '/addwine/content/pages/clients',
      },
      PARTNERS: {
        PATH: 'partners',
        INDEX: '/addwine/content/pages/partners',
      },
      VACANCIES: {
        PATH: 'vacancies',
        INDEX: '/addwine/content/pages/vacancies',
      },
      TEAM: {
        PATH: 'team',
        INDEX: '/addwine/content/pages/team',
      },
      REVIEWS: {
        PATH: 'reviews',
        INDEX: '/addwine/content/pages/reviews',
      },
      PROCESSING_POLICY: {
        PATH: 'processing-policy',
        INDEX: '/addwine/content/pages/processing-policy',
      },
      PUBLIC_OFFER: {
        PATH: 'public-offer',
        INDEX: '/addwine/content/pages/public-offer',
      },
      COOKIES_POLICY: {
        PATH: 'cookies-policy',
        INDEX: '/addwine/content/pages/cookies-policy',
      },
      CERTIFICATES_POLICY: {
        PATH: 'certificates-policy',
        INDEX: '/addwine/content/pages/certificates-policy',
      },
      FAQ: {
        PATH: 'faq',
        INDEX: '/addwine/content/pages/faq',
      },
    },
    SEO: {
      PATH: 'seo',
      INDEX: '/addwine/content/seo',
      ROBOTS_TXT: {
        PATH: 'robots-txt',
        INDEX: '/addwine/content/seo/robots-txt',
      },
      SITEMAP: {
        PATH: 'sitemap',
        INDEX: '/addwine/content/seo/sitemap',
      },
    },
    SEO_TEXTS: {
      path: '/seo-texts',
      INDEX: '/addwine/content/seo-texts',
      VIEW: '/addwine/content/seo-texts/:id',
    },
    SEO_FAQS: {
      path: '/seo-faqs',
      INDEX: '/addwine/content/seo-faqs',
      VIEW: '/addwine/content/seo-faqs/:id',
    },
    FAQS: {
      INDEX: '/addwine/content/faqs',
      CATEGORIES: {
        INDEX: '/addwine/content/faqs/categories',
      },
      FAQS: {
        INDEX: '/addwine/content/faqs/faqs',
      },
    },
    SEO_TEMPLATES: {
      INDEX: '/addwine/content/seo-templates',
    },
    VACANCIES: {
      INDEX: '/addwine/content/vacancies',
    },
    REVIEWS: {
      INDEX: '/addwine/content/reviews',
    },
    TEAM_MEMBERS: {
      INDEX: '/addwine/content/team-members',
    },
    CLIENTS: {
      INDEX: '/addwine/content/clients',
      CATEGORIES: {
        INDEX: '/addwine/content/clients/categories',
      },
      CLIENTS: {
        INDEX: '/addwine/content/clients/clients',
      },
    },
    PARTNERS: {
      INDEX: '/addwine/content/partners',
      CATEGORIES: {
        INDEX: '/addwine/content/partners/categories',
      },
      PARTNERS: {
        INDEX: '/addwine/content/partners/partners',
      },
    },
    DOCUMENTS: {
      INDEX: '/addwine/content/documents',
    },
    LOYALTY_PROGRAM: {
      PATH: 'loyalty-program',
      INDEX: '/loyalty-program',
      LIST: '/addwine/loyalty-program',
      CREATE: '/addwine/loyalty-program/create',
      UPDATE: '/addwine/loyalty-program/edit/:id',
    },
  },
  USERS: {
    PATH: 'users',
    INDEX: '/users',
    LIST: '/addwine/users',
    CREATE: '/addwine/users/create',
    UPDATE: '/addwine/users/edit/:id',
  },
  SUBSCRIPTIONS: {
    PATH: 'subscriptions',
    INDEX: '/subscriptions',
  },
  ANALYTICS: {
    PATH: 'analytics',
    INDEX: '/analytics',
  },
  SETTINGS: {
    PATH: 'settings',
    INDEX: '/settings',
    PAYMENT: {
      INDEX: '/addwine/settings/payment',
    },
    DELIVERY_DAYS: {
      INDEX: '/addwine/settings/delivery-days',
    },
    NOTIFICATIONS: {
      PATH: 'notifications',
      INDEX: '/addwine/settings/notifications',
    },
  },
  FEEDBACKS: {
    PATH: 'feedbacks',
    INDEX: '/feedbacks',
  },
}

export const ADDWINE_ADMIN_ROUTES_LABELS = {
  [ADDWINE_ADMIN_ROUTES.CATALOG_INDEX]: 'Каталог',
  [ADDWINE_ADMIN_ROUTES.PAYMENTS_INDEX]: 'Оплата и доставка',
  [ADDWINE_ADMIN_ROUTES.ORDERS_INDEX]: 'Заказы',
  [ADDWINE_ADMIN_ROUTES.BLOG_INDEX]: 'Статьи',
  [ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX]: 'Акции и промокоды',
  [ADDWINE_ADMIN_ROUTES.CONTENT_INDEX]: 'Контент и SEO',
  [ADDWINE_ADMIN_ROUTES.USERS_INDEX]: 'Пользователи',
  [ADDWINE_ADMIN_ROUTES.SUBSCRIPTIONS_INDEX]: 'Подписки и предзаказы',
  [ADDWINE_ADMIN_ROUTES.ANALYTICS_INDEX]: 'Аналитика',
  [ADDWINE_ADMIN_ROUTES.SETTINGS_INDEX]: 'Настройки',
  [ADDWINE_ADMIN_ROUTES.FEEDBACKS_INDEX]: 'Консультации',
  [ADDWINE_ADMIN_ROUTES.SETTINGS_PDF_INDEX]: 'Настройки PDF',
  [ADDWINE_ADMIN_ROUTES.PROGRAM_LOYALTY_INDEX]: 'Программа лояльности',
}

export const ADDSELLERS_ADMIN_ROUTES = {
  INDEX: '/sellers',
  SETTINGS_INDEX: '/sellers/settings',
  CLIENT_REQUESITES_INDEX: '/sellers/client-requesites',
  ORDERS_INDEX: '/sellers/orders',
  BRANDS_INDEX: '/sellers/brands',
  BONUS_PROGRAM_INDEX: '/sellers/bonus-program',
  DEALERS_INDEX: '/sellers/dealers',
  CONTENT_INDEX: '/sellers/content',
  DOWNLOADS_INDEX: '/sellers/downloads',
  OFFERS_INDEX: '/sellers/offers',
  SUPPLIERS_INDEX: '/sellers/suppliers',
  PRODUCTS_INDEX: '/sellers/products',
  CATALOGUE_INDEX: '/sellers/catalogs',
  CHATS_INDEX: '/sellers/chats',
  TEMPLATE_INDEX: '/sellers/templates',
  VIDEOS_INDEX: '/sellers/instructional-videos',
  MARKETING_NOTIFICATIONS_INDEX: '/sellers/marketing-notifications',
  SUBSCRIPTIONS_INDEX: '/sellers/subscriptions',
  FEEDBACKS_INDEX: '/sellers/feedbacks',
  PRICE_POLICY_INDEX: '/sellers/prices-policy',
  EDIT_PATH: 'edit/:id',
  GET_ONE_PATH: ':id',
  CREATE_PATH: 'create',
  CHATS: {
    PATH: 'chats',
    VIEW: '/sellers/chats/:id',
  },
  ORDERS: {
    INDEX: '/sellers/orders',
    PATH: 'orders',
    ADDSELLERS: {
      PATH: 'addsellers',
      INDEX: '/addsellers',
      LIST: '/sellers/orders/addsellers',
      VIEW: '/sellers/orders/addsellers/:id',
      UPDATE: '/sellers/orders/addsellers/edit/:id',
    },
    OFFSELLERS: {
      PATH: 'offsellers',
      INDEX: '/offsellers',
      LIST: '/sellers/orders/offsellers',
      VIEW: '/sellers/orders/offsellers/:id',
    },
  },
  BRANDS: {
    PATH: 'brands',
    INDEX: '/brands',
    LIST: '/sellers/brands',
    CREATE: '/sellers/brands/create',
    UPDATE: '/sellers/brands/edit/:id',
  },
  BONUS_PROGRAM: {
    PATH: 'bonus-program',
    INDEX: '/bonus-program',
    LIST: '/sellers/bonus-program',
    CREATE: '/sellers/bonus-program/create',
    UPDATE: '/sellers/bonus-program/edit/:id',
    EXCLUDE_DEALERS: {
      PATH: ':id/exclude-dealers',
      INDEX: '/sellers/bonus-program/:id/exclude-dealers',
    },
    ACTIVE: {
      PATH: ':id/active',
      INDEX: '/sellers/bonus-program/:id/active',
    },
    ARCHIVE: {
      PATH: ':id/archive',
      INDEX: '/sellers/bonus-program/:id/archive',
    },
  },
  DEALERS: {
    INDEX: '/sellers/dealers',
    LIST: '/sellers/dealers/list',
    LIST_WITH_BONUS_COUNT: '/sellers/dealers/bonus-count',
    EDIT: '/sellers/dealers/edit-account/:id',
    CREATE: '/sellers/dealers/create-account',
    CLIENT_REQUESITES: {
      INDEX: '/sellers/dealers/:id/client-requesites',
      CREATE: '/sellers/dealers/:id/client-requesites/create',
      EDIT: '/sellers/client-requesites/:id',
    },
    ORDERS: {
      LIST: '/sellers/dealers/:id/orders',
      CREATE: '/sellers/dealers/:id/orders/create',
    },
    DELIVERY_ADDRESSES: {
      INDEX: '/sellers/dealers/:id/delivery-addresses',
      CREATE: '/sellers/dealers/:id/delivery-addresses/create',
      EDIT: '/sellers/dealers/:id/delivery-addresses/:addressId',
    },
    RECIPIENTS: {
      INDEX: '/sellers/dealers/:id/recipients',
      CREATE: '/sellers/dealers/:id/recipients/create',
      EDIT: '/sellers/dealers/:id/recipients/:recipientId',
    },
    BONUS_TRANSACTIONS: {
      PATH: ':id/bonus-transactions',
      LIST: '/sellers/dealers/:id/bonus-transactions',
    },
    NOTES: {
      PATH: 'notes',
      LIST: '/sellers/dealers/notes',
    },
  },
  CONTENT: {
    PATH: 'content',
    INDEX: '/content',
    LIST: '/sellers/content',
    SEOFAQS: {
      PATH: 'seo-faqs',
      LIST: '/sellers/content/seo-faqs',
      EDIT: '/sellers/content/seo-faqs/:id',
      CREATE: '/sellers/content/seo-faqs/create',
    },
    PAGES: {
      PATH: 'pages',
      INDEX: '/sellers/content/pages',
      LIST: {
        PATH: 'list',
        INDEX: '/sellers/content/pages/list',
      },
      RETAILERS: {
        PATH: 'retailers',
        INDEX: '/sellers/content/pages/retailers',
      },
      SITE_SETTINGS: {
        PATH: 'site-settings',
        INDEX: '/sellers/content/pages/site-settings',
      },
      PUBLIC_OFFER: {
        PATH: 'public-offer',
        INDEX: '/sellers/content/pages/public-offer',
      },
      CONFIDENTIAL_POLICY: {
        PATH: 'confidential-policy',
        INDEX: '/sellers/content/pages/confidential-policy',
      },
      SUPPLIERS: {
        PATH: 'suppliers',
        INDEX: '/sellers/content/pages/suppliers',
      },
      BONUS_PROGRAM: {
        PATH: 'bonus-program',
        INDEX: '/sellers/content/pages/bonus-program',
      },
    },
  },
  MARKETING_NOTIFICATIONS: {
    path: 'marketing-notifications',
    LIST: '/sellers/marketing-notifications',
    EDIT: '/sellers/marketing-notifications/edit/:id',
  },
  CATALOGS: {
    INDEX: '/catalogs',
    UPDATE: '/sellers/catalogs/:id/edit',
  },
  CLIENT_REQUISITES: {
    PATH: 'client-requesites',
    LIST: '/sellers/client-requesites',
    EDIT: '/sellers/client-requesites/:id',
  },
}

export const ACCESS_MAP = {
  //MANAGERS
  [MANAGERS_ROUTES.INDEX]: [ROLES.OWNER],
  [MANAGERS_ROUTES.UPDATE]: [ROLES.OWNER],
  [MANAGERS_ROUTES.CREATE]: [ROLES.OWNER],

  //SELLERS
  [ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.LIST]: [
    ROLES.REMOTE_MANAGER,
    ROLES.OWNER,
    ROLES.ADMIN,
    ROLES.MANAGER,
  ],

  [ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.ACTIVE.INDEX]: [
    ROLES.REMOTE_MANAGER,
    ROLES.OWNER,
    ROLES.ADMIN,
    ROLES.MANAGER,
  ],

  [ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.LIST]: [
    ROLES.REMOTE_MANAGER,
    ROLES.OWNER,
    ROLES.ADMIN,
    ROLES.MANAGER,
  ],

  [ADDSELLERS_ADMIN_ROUTES.ORDERS_INDEX]: [
    ROLES.REMOTE_MANAGER,
    ROLES.OWNER,
    ROLES.ADMIN,
    ROLES.MANAGER,
  ],

  [ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.LIST]: [
    ROLES.REMOTE_MANAGER,
    ROLES.OWNER,
    ROLES.ADMIN,
    ROLES.MANAGER,
  ],

  [ADDSELLERS_ADMIN_ROUTES.ORDERS.OFFSELLERS.LIST]: [
    ROLES.REMOTE_MANAGER,
    ROLES.OWNER,
    ROLES.ADMIN,
    ROLES.MANAGER,
  ],

  [ADDSELLERS_ADMIN_ROUTES.ORDERS.OFFSELLERS.VIEW]: [
    ROLES.REMOTE_MANAGER,
    ROLES.OWNER,
    ROLES.ADMIN,
    ROLES.MANAGER,
  ],

  [ADDSELLERS_ADMIN_ROUTES.CHATS_INDEX]: [
    ROLES.REMOTE_MANAGER,
    ROLES.OWNER,
    ROLES.ADMIN,
    ROLES.MANAGER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.CHATS.VIEW]: [
    ROLES.REMOTE_MANAGER,
    ROLES.OWNER,
    ROLES.ADMIN,
    ROLES.MANAGER,
  ],

  [ADDSELLERS_ADMIN_ROUTES.INDEX]: [
    ROLES.REMOTE_MANAGER,
    ROLES.OWNER,
    ROLES.ADMIN,
    ROLES.MANAGER,
  ],

  // // SELLERS DEALERS
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.INDEX]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.CREATE]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.EDIT]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.CLIENT_REQUESITES.INDEX]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.CLIENT_REQUESITES.CREATE]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.CLIENT_REQUESITES.EDIT]: [
    ROLES.ADMIN,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.NOTES.LIST]: [ROLES.ADMIN, ROLES.OWNER],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.ORDERS.LIST]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.ORDERS.CREATE]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.VIEW]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.UPDATE]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.DELIVERY_ADDRESSES.INDEX]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.DELIVERY_ADDRESSES.CREATE]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.DELIVERY_ADDRESSES.EDIT]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.RECIPIENTS.INDEX]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.RECIPIENTS.CREATE]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
  [ADDSELLERS_ADMIN_ROUTES.DEALERS.RECIPIENTS.EDIT]: [
    ROLES.REMOTE_MANAGER,
    ROLES.ADMIN,
    ROLES.MANAGER,
    ROLES.OWNER,
  ],
}

export const ADDSELLERS_ADMIN_ROUTES_LABELS = {
  [ADDSELLERS_ADMIN_ROUTES.SETTINGS_INDEX]: 'Настройки',
  [ADDSELLERS_ADMIN_ROUTES.CLIENT_REQUESITES_INDEX]: 'Реквизиты клиентов',
  [ADDSELLERS_ADMIN_ROUTES.ORDERS_INDEX]: 'Заказы',
  [ADDSELLERS_ADMIN_ROUTES.BRANDS_INDEX]: 'Бренды',
  [ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM_INDEX]: 'Бонусная программа',
  [ADDSELLERS_ADMIN_ROUTES.DEALERS_INDEX]: 'Дилеры',
  [ADDSELLERS_ADMIN_ROUTES.CONTENT_INDEX]: 'Контент и SEO',
  [ADDSELLERS_ADMIN_ROUTES.DOWNLOADS_INDEX]: 'Загрузка файлов',
  [ADDSELLERS_ADMIN_ROUTES.OFFERS_INDEX]: 'Акции',
  [ADDSELLERS_ADMIN_ROUTES.SUPPLIERS_INDEX]: 'Поставщики',
  [ADDSELLERS_ADMIN_ROUTES.PRODUCTS_INDEX]: 'Главный каталог',
  [ADDSELLERS_ADMIN_ROUTES.CATALOGUE_INDEX]: 'Каталоги',
  [ADDSELLERS_ADMIN_ROUTES.CHATS_INDEX]: 'Чаты',
  [ADDSELLERS_ADMIN_ROUTES.TEMPLATE_INDEX]: 'Шаблоны',
  [ADDSELLERS_ADMIN_ROUTES.VIDEOS_INDEX]: 'Видеоматериалы',
  [ADDSELLERS_ADMIN_ROUTES.MARKETING_NOTIFICATIONS_INDEX]: 'Марк. уведомления',
  [ADDSELLERS_ADMIN_ROUTES.SUBSCRIPTIONS_INDEX]: 'Подписки',
  [ADDSELLERS_ADMIN_ROUTES.FEEDBACKS_INDEX]: 'Заявки',
  [ADDSELLERS_ADMIN_ROUTES.PRICE_POLICY_INDEX]: 'Список цен. политик',
}

export const ADDEVENT_ROUTES = {
  CATALOG: {
    INDEX: 'catalog',
    CATEGORY: 'catalog/category/:slug',
    PRODUCT: 'catalog/:slug',
  },
  ARTICLES: {
    ARTICLE: 'articles/:categorySlug/:slug',
  },
}

export const ADDEVENT_ADMIN_ROUTES = {
  INDEX: '/addevent',
  PATH: 'addevent',
  CREATE_PATH: 'create',
  UPDATE_PATH: ':id',
  CATALOG_PATH: ':id/catalog',
  CATALOG_INDEX: '/addevent/catalog',
  ORDERS_INDEX: '/addevent/orders',
  ORDERS_CALENDAR_INDEX: '/addevent/orders-calendar',
  SETTINGS_INDEX: '/addevent/settings',
  BLOG_INDEX: '/addevent/blog',
  CONTENT_INDEX: '/addevent/content',
  CATALOG: {
    PATH: 'catalog',
    INDEX: '/catalog',
    CATEGORIES: {
      PATH: 'categories',
      INDEX: '/categories',
      LIST: '/addevent/catalog/categories',
      UPDATE: '/addevent/catalog/categories/:id',
      CREATE: '/addevent/catalog/categories/create',
      CATALOG: '/addevent/catalog/categories/:id/catalog',
    },
    SOLUTIONS: {
      PATH: 'solutions',
      INDEX: '/solutions',
      LIST: '/addevent/catalog/solutions',
      UPDATE: '/addevent/catalog/solutions/:id',
      CREATE: '/addevent/catalog/solutions/create',
    },
    PRODUCTS: {
      PATH: 'products',
      INDEX: '/products',
      LIST: '/addevent/catalog/products',
      UPDATE: '/addevent/catalog/products/:id',
      CREATE: '/addevent/catalog/products/create',
    },
    BRANDS: {
      PATH: 'brands',
      INDEX: '/brands',
      LIST: '/addevent/catalog/brands',
    },
    TAGS: {
      PATH: 'tags',
      INDEX: '/tags',
      LIST: '/addevent/catalog/tags',
    },
    ASSORTMENTS: {
      PATH: 'assortments',
      INDEX: '/assortments',
      LIST: '/addevent/catalog/assortments',
      UPDATE: '/addevent/catalog/assortments/:id',
      CREATE: '/addevent/catalog/assortments/create',
    },
  },
  ORDERS: {
    PATH: 'orders',
    INDEX: '/orders',
    LIST: '/addevent/orders',
    CREATE: '/addevent/orders/create',
    UPDATE: '/addevent/orders/:id',
  },
  ORDERS_CALENDAR: {
    PATH: 'orders-calendar',
    INDEX: '/orders-calendar',
  },
  BLOG: {
    PATH: 'blog',
    INDEX: '/blog',
    CATEGORIES: {
      PATH: 'article-categories',
      INDEX: '/article-categories',
      LIST: '/addevent/blog/article-categories',
      CREATE: '/addevent/blog/article-categories/create',
      UPDATE: '/addevent/blog/article-categories/:id',
    },
    ARTICLES: {
      PATH: 'articles',
      INDEX: '/articles',
      LIST: '/addevent/blog/articles',
      CREATE: '/addevent/blog/articles/create',
      UPDATE: '/addevent/blog/articles/:id',
    },
  },
  CONTENT: {
    PATH: 'content',
    INDEX: '/content',
    LIST: '/addevent/content',
    PAGES: {
      PATH: 'pages',
      INDEX: '/addevent/content/pages',
      UPDATE: '/addevent/content/pages/:slug',
    },
    SEO_TEMPLATES: {
      PATH: 'seo-templates',
      INDEX: '/addevent/content/seo-templates',
    },
    COMMON: {
      PATH: 'common',
      INDEX: '/addevent/content/common',
    },
    WORKING_WITH_US: {
      PATH: 'workingwithus',
      INDEX: '/addevent/content/workingwithus',
    },
  },
}

export const ADDEVENT_ADMIN_ROUTES_LABELS = {
  [ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX]: 'Каталог',
  [ADDEVENT_ADMIN_ROUTES.ORDERS_INDEX]: 'Заказы',
  [ADDEVENT_ADMIN_ROUTES.ORDERS_CALENDAR_INDEX]: 'Календарь заказов',
  [ADDEVENT_ADMIN_ROUTES.SETTINGS_INDEX]: 'Настройки',
  [ADDEVENT_ADMIN_ROUTES.BLOG_INDEX]: 'Блог',
  [ADDEVENT_ADMIN_ROUTES.CONTENT_INDEX]: 'Контент и SEO',
}

export const CHATS_ROUTES = {
  CHATS_INDEX: '/chats',
}

export const getRoute = ({ route, site, params = null, query = null }) => {
  const sites = {
    [PROJECTS.ADDWINE]: ADDWINE_URL,
    [PROJECTS.ADDLANDINGS]: ADDLANDINGS_URL,
    [PROJECTS.ADDSELLERS]: ADDSELLERS_URL,
    [PROJECTS.ADDEVENTS]: ADDEVENT_URL,
  }

  let routeString = route

  if (params) {
    routeString = routeString.replace(
      /:(\w+)/g,
      (_, param) => params[param] || '',
    )
  }

  routeString = (sites[site] ?? '') + routeString

  if (query) {
    const queryString = `?${Object.keys(query)
      .map((e) => `${e}=${query[e]}`)
      .join('&')}`
    routeString = routeString.concat(queryString)
  }

  return routeString
}
