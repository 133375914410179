import ApiConnector from '../connector'
class SeoTemplatesActions {
  async getList() {
    const result = {
      value: null,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.get(`/v2/admin/seo-templates`)
      if (response.status === 200) {
        result.value = response.data;
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (err) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  async saveOne(template) {
    const result = {
      error: null,
    }

    try {
      const response = await ApiConnector.connector.post(`/v2/admin/seo-templates`, template)

      if (response.status !== 201) {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (err) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new SeoTemplatesActions()