import delivery from '@/delivery'

export default {
  state: () => ({
    page: 1,
    limit: 20,
    list: [],
    count: 0,
    search: null,
    orderBy: null,
    orderDir: null
  }),

  getters: {
    pageDealersCount(state) {
      return Math.ceil(state.count / state.limit)
    }
  },

  mutations: {
    setDealersList(state, orders) {
      state.list = orders
    },
    setCount(state, count) {
      state.count = count
    },
    setPage(state, page) {
      state.page = page
    },
    setSearch(state, search) {
      state.search = search
    },
    setPageLimit(state, limit) {
      state.limit = limit
    },
    setOrderBy(state, orderBy) {
      state.orderBy = orderBy
    },
    setOrderDir(state, orderDir) {
      state.orderDir = orderDir
    }
  },

  actions: {
    async getDealers({ commit, state }) {
      const {
        value,
        error
      } = await delivery.AddwineCore.DealersActions.getList({
        page: state.page,
        limit: state.limit,
        search: state.search,
        orderBy: state.orderBy,
        orderDir: state.orderDir
      })
      if (error) return
      commit('setDealersList', value.data)
      commit('setCount', value.meta.count)
    }
  }
}
