import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class BonusProgramActions {
  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v1/admin/bonus-program`, data),
      201
    )
  }

  getList = async (query) => {
    const params = {
      order_by: query.orderBy ?? 'starts_at',
      order_dir: query.orderDir ?? 'desc',
      limit: query.limit ?? 0,
      page: query.page ?? 0
    }

    if (query.status) {
      params.status = query.status ?? ''
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/bonus-program`, { params })
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/bonus-program/${id}`)
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v1/admin/bonus-program/${id}`, data)
    )
  }

  start = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v1/admin/bonus-program/${id}/start`),
      204,
      true
    )
  }

  finishById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v1/admin/bonus-program/${id}/finish`),
      204
    )
  }

  getActiveProgramStatisticsById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(
        `/v1/admin/bonus-program/${id}/active-statistics`
      ),
      200,
      true
    )
  }

  getArchiveStatistic = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/bonus-program/${id}/statistics`)
    )
  }

  excludeDealers = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(
        `/v1/admin/bonus-program/${id}/excluded-dealers`,
        data
      ),
      201
    )
  }

  includeDealers = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(
        `v1/admin/bonus-program/${id}/excluded-dealers`,
        { data }
      ),
      204
    )
  }
}

export default new BonusProgramActions()
