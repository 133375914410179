import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router/index.js'
import directives from './directives'
import '@/assets/styles/common.scss'

import VModal from 'vue-js-modal'
import Transitions from 'vue2-transitions'

import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import LightGallery from 'vue-light-gallery'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import YmapPlugin from 'vue-yandex-maps'

import AddwineUI from '@add-wine/addwine-ui'
import AddwineUIClient from '@add-wine/addwine-ui/dist/addwine-ui-client.es'
import '@add-wine/addwine-ui/dist/style.css'

import DefaultLayout from '@/layouts/default.vue'
import AuthLayout from '@/layouts/auth.vue'

const configData = {
  s3_link: process.env.VUE_APP_S3_URL,
  addwine_link: process.env.VUE_APP_ADW_URL,
  sellers_link: process.env.VUE_APP_ADS_URL,
  addevents_link: process.env.VUE_APP_ADDEVENT_URL,
  api_link: process.env.VUE_APP_API_URL,
  yandex_map_api_key: process.env.VUE_APP_YANDEX_MAP_API_KEY,
  landing_url: process.env.VUE_APP_LANDING_URL,
  tiny_mce_key: process.env.VUE_APP_TINY_MCE_KEY,
}

const yandexMapSettings = {
  apiKey: 'fe95027d-bdc8-45f7-83fa-0ae71a538367',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1',
}

Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)

Vue.prototype.$configData = configData

Vue.use(YmapPlugin, yandexMapSettings)
Vue.use(LightGallery)
Vue.use(VModal)
Vue.use(Transitions)
Vue.use(VueGoodTablePlugin)
Vue.use(ElementUI, { locale })
Vue.use(AddwineUI)
Vue.use(AddwineUIClient)

Vue.config.productionTip = false
store.dispatch('getSelfManager')

directives.forEach((directive) => {
  Vue.directive(directive.name, directive)
})

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')
