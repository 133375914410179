import ApiConnector from '../connector'
import { camelToSnakeCase } from '@/helpers/convertString'
import { apiRequestWrapper } from '@/helpers/delivery'
class ManagersActions {
  getList = async (query) => {
    const params = {
      page: query.page || 1,
      limit: query.limit || 20
    }
    if (query.search) {
      params.search = query.search
    }
    if (query.roles?.length) {
      params.roles = query.roles?.join(',')
    }
    if (query.additionalRoles?.length) {
      params.additional_roles = query.additionalRoles?.join(',')
    }
    if (query.projectPermissions?.length) {
      params.project_permissions = query.projectPermissions?.join(',')
    }
    if (query.availableUponRegistration) {
      params.available_upon_registration = query.availableUponRegistration
    }
    if (query.orderBy) {
      params.order_by = camelToSnakeCase(query.orderBy)
    }
    if (query.orderDir) {
      params.order_dir = query.orderDir
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/manager-account`, {
        params
      })
    )
  }
  getById = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/manager-account/${id}`
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  create = async (data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.post(
        '/v1/admin/manager-account',
        data
      )

      if (response.status === 201) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      if (e.response.status === 400) {
        result.error = this.checkError(e.response.data.errors[0])
        return result
      }
    }

    return result
  }
  update = async (id, data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.put(
        `/v1/admin/manager-account/${id}`,
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      if (e.response.status === 400) {
        result.error = this.checkError(e.response.data.errors[0])
        return result
      }
    }

    return result
  }

  checkError = (error) => {
    switch (true) {
      case error.includes('ERROR: duplicate key value violates unique'):
        return 'Менеджер с таким e-mail уже существует'
      case error.includes("MANAGER ACCOUNT PASSWORD DOESN'T MATCH"):
        return 'Неверный старый пароль'
      case error.includes('DEFAULT MANAGER ACCOUNT ALREADY EXIST'):
        return 'Менеджер по умолчанию уже существует'
      case error.includes('DEFAULT COORDINATOR ALREADY EXIST'):
        return 'Координатор по умолчанию уже существует'
      default:
        return 'Network error, try again later'
    }
  }

  delete = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v1/admin/manager-account/${id}`
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new ManagersActions()
