import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class DaDataActions {
  getAddressSuggests = async (query) => {
    const params = { query }
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/dadata/suggest/address`, { params }),
      200
    )
  }
}

export default new DaDataActions()
