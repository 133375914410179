import connector from './connector'
import PagesActions from './PagesActions'
import BlogArticles from './BlogArticles'
import ImagesActions from './ImagesActions'
import PromoArticlesActions from './PromoArticlesActions'
import PdfCatalogActions from './PdfCatalogActions'
import SeoTemplatesActions from './SeoTemplatesActions'
import SeoTextsActions from './SeoTextsActions'
import ArticleCategoriesActions from './ArticleCategoriesActions'
import FAQCategoriesActions from './FAQCategoriesActions'
import FAQsActions from './FAQsActions'
import SeoFaqsActions from './SeoFaqsActions'
import VacanciesActions from './VacanciesActions'
import ReviewsActions from './ReviewsActions'
import TeamMembersActions from './TeamMembersActions'
import ClientCategoriesActions from './ClientCategoriesActions'
import ClientsActions from './ClientsActions'
import PartnerCategoriesActions from './PartnerCategoriesActions'
import PartnersActions from './PartnersActions'
import DocumentsActions from './DocumentsActions'
import SeosActions from './SeosActions'

class ContentServiceCore {
  connector = connector
  PagesActions = PagesActions
  BlogArticles = BlogArticles
  ImagesActions = ImagesActions
  PromoArticlesActions = PromoArticlesActions
  SeoTemplatesActions = SeoTemplatesActions
  SeoTextsActions = SeoTextsActions
  ArticleCategoriesActions = ArticleCategoriesActions
  FAQCategoriesActions = FAQCategoriesActions
  FAQsActions = FAQsActions
  SeoFaqsActions = SeoFaqsActions
  VacanciesActions = VacanciesActions
  ReviewsActions = ReviewsActions
  TeamMembersActions = TeamMembersActions
  ClientCategoriesActions = ClientCategoriesActions
  ClientsActions = ClientsActions
  PartnerCategoriesActions = PartnerCategoriesActions
  PartnersActions = PartnersActions
  DocumentsActions = DocumentsActions
  SeosActions = SeosActions
  PdfCatalogActions = PdfCatalogActions
}

export default new ContentServiceCore()
