import ApiConnector from '../connector'

class InstructionalVideosActions {
  getList = async (query) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        '/v1/admin/instructional-videos',
        {
          params: { limit: query.limit, page: query.page, active: query.active }
        }
      )
      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  getById = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/instructional-videos/${id}`
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  createVideo = async (data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.post(
        '/v1/admin/instructional-videos',
        data
      )

      if (response.status === 201) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  updateVideo = async (id, data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.put(
        `/v1/admin/instructional-videos/${id}`,
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  deleteVideo = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v1/admin/instructional-videos/${id}`
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  updateInstructionalVideos = async (data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.put(
        '/v1/admin/instructional-videos/order',
        data
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new InstructionalVideosActions()
