import axios from 'axios'

class GeoCodeActions {
  geocodeAddress = async (city, street) => {
    const result = {
      value: false,
      error: null
    }
    try {
      const response = await axios.get(
        `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${process.env.VUE_APP_YANDEX_MAP_API_KEY}&geocode=Россия+` +
          `${city + ' ' + street}`
      )

      if (response.status === 200) {
        result.value = response
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new GeoCodeActions()
