<template>
  <el-menu
    default-active="1"
    :default-openeds="['1', '2', '3']"
    :class="['el-menu-vertical-demo', $style.sidebar]"
  >
    <a :href="$configData.addwine_link" target="_blank">
      <el-menu-item :class="[$style.item, $style.logo]">
        <adw-icon name="logo" />
      </el-menu-item>
    </a>
    <div :class="$style.overflowWrapper">
      <el-submenu
        v-if="accessLandingRoutes?.length && $store.getters.landingsAccess"
        index="1"
      >
        <template slot="title">
          <div :class="$style.title">
            <i class="el-icon-menu"></i>
            <span>AddLandings</span>
          </div>
        </template>
        <el-menu-item-group>
          <router-link
            v-for="(key, i) in accessLandingRoutes"
            :key="i + 'L'"
            :to="$options.LANDING_ROUTES[key]"
            :class="$style.item"
          >
            <el-menu-item
              v-if="
                !$options.LANDING_ROUTES[key].includes(
                  $options.LANDING_ROUTES.VIEW,
                )
              "
              :index="i + 'L'"
            >
              <p>
                {{
                  $options.LANDING_ROUTES_LABELS[$options.LANDING_ROUTES[key]]
                }}
              </p>
            </el-menu-item>
          </router-link>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu
        v-if="accessAddwineRoutes?.length && $store.getters.addwineAccess"
        index="2"
      >
        <template slot="title">
          <div :class="$style.title">
            <i class="el-icon-goblet"></i><span>Addwine</span>
          </div>
        </template>
        <el-menu-item-group>
          <template v-for="(key, i) in accessAddwineRoutes">
            <router-link
              v-if="isIndexRoute(key)"
              :key="i + 'A'"
              :to="$options.ADDWINE_ADMIN_ROUTES[key]"
              :class="$style.item"
            >
              <el-menu-item :index="i + 'A'">
                <p>
                  {{
                    $options.ADDWINE_ADMIN_ROUTES_LABELS[
                      $options.ADDWINE_ADMIN_ROUTES[key]
                    ]
                  }}
                </p></el-menu-item
              >
            </router-link>
          </template>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu
        v-if="accessSellersRoutes?.length && $store.getters.sellersAccess"
        index="3"
      >
        <template slot="title">
          <div :class="$style.title">
            <i class="el-icon-sell"></i>
            <span>AddSellers</span>
          </div>
        </template>
        <el-menu-item-group>
          <template v-for="(key, i) in accessSellersRoutes">
            <router-link
              v-if="isIndexRoute(key)"
              :key="i + 'S'"
              :to="$options.ADDSELLERS_ADMIN_ROUTES[key]"
              :class="$style.item"
            >
              <el-menu-item :index="i + 'S'">
                <p>
                  {{
                    $options.ADDSELLERS_ADMIN_ROUTES_LABELS[
                      $options.ADDSELLERS_ADMIN_ROUTES[key]
                    ]
                  }}
                </p>
              </el-menu-item>
            </router-link>
          </template>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu v-if="accessAddEventsRoutes?.length" index="4">
        <template slot="title">
          <div :class="$style.title">
            <i class="el-icon-sell"></i>
            <span>Addevent</span>
          </div>
        </template>
        <el-menu-item-group>
          <template v-for="(key, i) in accessAddEventsRoutes">
            <router-link
              v-if="isIndexRoute(key)"
              :key="i + 'E'"
              :to="$options.ADDEVENT_ADMIN_ROUTES[key]"
              :class="$style.item"
            >
              <el-menu-item :index="i + 'E'">
                <p>
                  {{
                    $options.ADDEVENT_ADMIN_ROUTES_LABELS[
                      $options.ADDEVENT_ADMIN_ROUTES[key]
                    ] ?? key
                  }}
                </p>
              </el-menu-item>
            </router-link>
          </template>
        </el-menu-item-group>
      </el-submenu>
      <router-link
        v-if="isOwner"
        :class="$style.item"
        :to="$options.MANAGERS_ROUTES.INDEX"
      >
        <el-menu-item>
          <i class="el-icon-user"></i>
          <p>Менеджеры</p>
        </el-menu-item>
      </router-link>
      <router-link :class="$style.item" :to="$options.PROFILE_ROUTES.INDEX">
        <el-menu-item>
          <i class="el-icon-user"></i>
          <p>Профиль</p>
        </el-menu-item>
      </router-link>
      <template v-for="(e, i) in accessChatsRoutes">
        <router-link
          v-if="$store.getters.coordinatorAccess"
          :key="i + 'C'"
          :class="$style.item"
          :to="$options.CHATS_ROUTES[e]"
        >
          <el-menu-item>
            <i class="el-icon-chat-dot-round"></i>
            <p>Чаты координатора</p>
          </el-menu-item>
        </router-link>
      </template>
    </div>
    <el-menu-item :class="$style.item" @click="$store.dispatch('logout')">
      <i class="el-icon-switch-button"></i>
      <p>Выйти</p>
    </el-menu-item>
  </el-menu>
</template>

<script>
import {
  getRoute,
  CHATS_ROUTES,
  ADDWINE_ADMIN_ROUTES,
  ADDWINE_ADMIN_ROUTES_LABELS,
  LANDING_ROUTES,
  LANDING_ROUTES_LABELS,
  ADDSELLERS_ADMIN_ROUTES,
  ADDSELLERS_ADMIN_ROUTES_LABELS,
  ADDEVENT_ADMIN_ROUTES,
  ADDEVENT_ADMIN_ROUTES_LABELS,
  PROFILE_ROUTES,
  MANAGERS_ROUTES,
} from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  CHATS_ROUTES,
  ADDWINE_ADMIN_ROUTES,
  ADDWINE_ADMIN_ROUTES_LABELS,
  LANDING_ROUTES,
  LANDING_ROUTES_LABELS,
  ADDSELLERS_ADMIN_ROUTES,
  ADDSELLERS_ADMIN_ROUTES_LABELS,
  ADDEVENT_ADMIN_ROUTES,
  ADDEVENT_ADMIN_ROUTES_LABELS,
  PROFILE_ROUTES,
  MANAGERS_ROUTES,
  computed: {
    ...mapGetters({
      filterAccessSidebarList: 'filterAccessSidebarList',
      isOwner: 'isOwner',
    }),
    accessLandingRoutes() {
      return this.filterAccessSidebarList(LANDING_ROUTES)
    },
    accessAddwineRoutes() {
      return this.filterAccessSidebarList(ADDWINE_ADMIN_ROUTES)
    },
    accessSellersRoutes() {
      return this.filterAccessSidebarList(ADDSELLERS_ADMIN_ROUTES)
    },
    accessAddEventsRoutes() {
      return this.filterAccessSidebarList(ADDEVENT_ADMIN_ROUTES)
    },
    accessChatsRoutes() {
      return this.filterAccessSidebarList(CHATS_ROUTES)
    },
  },
  methods: {
    getRoute({ route, params, site }) {
      return getRoute({ route, params, site })
    },
    isIndexRoute(key) {
      return (
        (ADDSELLERS_ADMIN_ROUTES[key] ||
          LANDING_ROUTES[key] ||
          ADDWINE_ADMIN_ROUTES[key] ||
          ADDEVENT_ADMIN_ROUTES[key]) &&
        key.includes('_INDEX')
      )
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors';

.sidebar {
  position: sticky;
  top: 0;
  height: 100vh;

  .logo {
    color: $black !important;
  }
  .overflowWrapper {
    overflow: auto;
    height: calc(100vh - 7.5rem);
  }
  .title {
    display: flex;
    align-items: center;
  }
  .item {
    text-decoration: none;
    &,
    & > li {
      display: flex;
      align-items: center;
      width: 100%;
    }
    p {
      @include text;
    }
  }
}
</style>
