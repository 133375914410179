export default {
  state: {
    info: true,
    publication: false,
  },
  
  mutations: {
    changeInfoStatusTrue(state) {
      state.info = true;
    },

    changeInfoStatusFalse(state) {
      state.info = false;
    },

    changePublicationStatusTrue(state) {
      state.publication = true;
    },

    changePublicationStatusFalse(state) {
      state.publication = false;
    },
  },
};
