import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'
import { SORTING_TYPES, SORTING_FIELDS } from '@/constants/common'

class SolutionsActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.orderBy ?? SORTING_FIELDS.CREATED_AT,
      order_dir: query?.orderDir ?? SORTING_TYPES.DESC,
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/solutions`, {
        params,
      }),
    )
  }

  getOne = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/solutions/${id}`),
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/solutions`, data),
      201,
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/solutions/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/solutions/${id}`),
      204,
    )
  }
}

export default new SolutionsActions()
