export const DEALERS_TYPES = [
  { value: 'Виноторговая компания', text: 'Виноторговая компания' },
  {
    value: 'Винный бутик или винотека',
    text: 'Винный бутик или винотека'
  },
  { value: 'Сомелье, кавист', text: 'Сомелье, кавист' },
  { value: 'Винный блогер', text: 'Винный блогер' },
  { value: 'Винодельня', text: 'Винодельня' },
  { value: 'Интернет-магазин', text: 'Интернет-магазин' },
  { value: 'Бар или ресторан', text: 'Бар или ресторан' },
  { value: 'Рекламное агентство', text: 'Рекламное агентство' },
  { value: 'Розничная точка продаж', text: 'Розничная точка продаж' }
]
export const DEALERS_BOOLEANS = [
  {
    name: 'Пусто',
    value: null
  },
  {
    name: 'Да',
    value: true
  },
  {
    name: 'Нет',
    value: false
  }
]
