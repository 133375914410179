import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class BrandsActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      search: query?.search || null,
      order_dir: query?.orderDir,
      order_by: query?.orderBy
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/brands`, {
        params
      })
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/brands/${id}`)
    )
  }
  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post('/v2/admin/brands/', data),
      201
    )
  }
  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/brands/${id}`, data)
    )
  }
  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/brands/${id}`),
      204
    )
  }
}

export default new BrandsActions()
