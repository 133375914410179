import ApiConnector from '../connector'

class CatalogsActions {
  getCatalog = async (query) => {
    const result = {
      value: null,
      error: null
    }

    try {
      const params = {
        page: query.page,
        limit: query.limit,
        order_by: query?.orderBy || 'name',
        order_dir: query?.orderDir || 'asc'
      }

      if (query.search) {
        params.search = query.search.trim()
      }

      if (query.havePresents) {
        params.have_presents = query.havePresents
      }

      if (query.haveExpressDelivery) {
        params.have_express_delivery = query.haveExpressDelivery
      }

      if (query.isActive) {
        params.is_active = query.isActive
      }

      if (query.isAvailable) {
        params.is_available = query.isAvailable
      }

      if (query.brands?.length) {
        params.brands = query.brands.join(',')
      }

      if (query.assortments?.length) {
        params.assortments = query.assortments.join(',')
      }

      if (query.categories?.length) {
        params.categories = query.categories.join(',')
      }

      if (query?.excludedAssortments?.length) {
        params.excluded_assortments = query.excludedAssortments.join(',')
      }

      if (query?.excludedCategories?.length) {
        params.excluded_categories = query.excludedCategories.join(',')
      }

      if (query?.supplier?.length) {
        params.supplier = query.supplier
      }

      const response = await ApiConnector.connector.get(`/v2/admin/catalog`, {
        params
      })

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (err) {
      result.error = new Error('Network error, try again late')
    }
    return result
  }
}

export default new CatalogsActions()
