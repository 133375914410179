import ApiConnector from '../connector'
import { camelToSnakeCase } from '../../../helpers/convertString'
import { apiRequestWrapper } from '@/helpers/delivery'

class OrdersActions {
  getList = async (query) => {
    const params = {
      page: query.page || 1,
      limit: query.limit || 20,
    }
    if (query.search) {
      params.search = query.search
    }
    if (query.orderBy) {
      params.order_by = camelToSnakeCase(query.orderBy)
    }
    if (query.orderDir) {
      params.order_dir = query.orderDir
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/orders`, {
        params,
      }),
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/orders/${id}`),
    )
  }

  getSearchList = async (query) => {
    const result = {
      value: null,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.get(`/v1/admin/orders`, {
        params: { limit: query.limit, search: query.search },
      })
      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v1/admin/orders`, data),
      201,
    )
  }

  confirm = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v1/admin/orders/${id}/confirm`, data),
      204,
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v1/admin/orders/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v1/admin/orders/${id}`),
      204,
    )
  }

  getOffSellersList = async (query) => {
    const params = {
      page: query.page || 1,
      limit: query.limit || 20,
    }
    if (query.search) {
      params.search = query.search
    }
    if (query.orderBy) {
      params.order_by = camelToSnakeCase(query.orderBy)
    }
    if (query.orderDir) {
      params.order_dir = query.orderDir
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/offseller-orders`, {
        params,
      }),
    )
  }

  getOffSellersSearchList = async (query) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/offseller-orders`, {
        params: { limit: query.limit, search: query.search },
      }),
    )
  }

  getOffSellersById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/offseller-orders/${id}`),
    )
  }

  createMsOrder = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v1/orders/${id}/ms`),
      204,
      true,
    )
  }
}

export default new OrdersActions()
