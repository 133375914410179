import ApiConnector from '../connector'

class ImagesActions {
  delete = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v2/admin/images/${id}`
      )
      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new ImagesActions()
