import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class UsersActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      search: query?.search || null
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/user-accounts`, { params })
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/user-accounts/${id}`)
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/user-accounts/`, data),
      201,
      true
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/user-accounts/${id}`, data),
      200,
      true
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/user-accounts/${id}`),
      204
    )
  }

  deleteAddress = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(
        `/v2/admin/user-accounts/delivery-addresses/${id}`
      ),
      204
    )
  }
  updateAddress = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(
        `/v2/admin/user-accounts/delivery-addresses/${id}`,
        data
      )
    )
  }

  createAddress = async (userId, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(
        `/v2/admin/user-accounts/delivery-addresses?user_id=${userId}`,
        data
      ),
      201
    )
  }

  checkSignUp = async (phone) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/user-accounts/sign-up`, {
        phone
      })
    )
  }
}

export default new UsersActions()
