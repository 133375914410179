import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class DeliveryPricesActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      search: query?.search || null,
      order_by: query?.orderBy || 'created_at',
      order_dir: query?.orderDir || 'desc'
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/delivery-prices`, {
        params
      })
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/delivery-prices/${id}`)
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/delivery-prices`, data),
      201
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/delivery-prices/${id}`, data)
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/delivery-prices/${id}`),
      204
    )
  }

  getAvailabilityByCoordinates = async (query) => {
    const params = {
      lat: query.geoLat,
      lng: query.geoLon,
      order_price: query.price
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(
        `/v2/delivery-prices/availability/by-coordinates`,
        {
          params
        }
      )
    )
  }
}

export default new DeliveryPricesActions()
