import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class DeliveryParamsActions {
  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/delivery-params/${id}`),
      204
    )
  }

  deletePaymentType = async (id, paymentTypeId) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(
        `/v2/admin/delivery-params/${id}/payment-types/${paymentTypeId}`
      ),
      204
    )
  }
}

export default new DeliveryParamsActions()
