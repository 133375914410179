import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class PaymentSettingsActions {
  get = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/payment-settings`)
    )
  }
  save = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/payment-settings`, data)
    )
  }
}

export default new PaymentSettingsActions()
