import ApiConnector from '../connector'

class AuthSellersActions {
  signIn = async (data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.post(
        '/v1/admin/auth/sign-in',
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      if (e.response.status === 400) {
        result.error =
          'Неверный логин или пароль. Проверьте правильность введенных данных.'
      } else {
        result.error = new Error('Network error, try again later')
      }
    }

    return result
  }

  refresh = async (token) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.post(
        '/v1/admin/auth/refresh-token',
        { refreshToken: token }
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  self = async () => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get('/v1/admin/auth/self')

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  baseRequest = async (config) => {
    return await ApiConnector.connector.request(config)
  }
}

export default new AuthSellersActions()
