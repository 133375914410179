import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class PartnersActions {

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post('/v2/admin/partners', data),
      201
    )
  }

  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.orderBy || 'order_field',
      order_dir: query?.orderDir || 'desc',
      search: query?.search || ''
    }
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/partners`, {
        params
      })
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/partners/${id}`)
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/partners/${id}`, data)
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/partners/${id}`),
      204
    )
  }
}

export default new PartnersActions()
