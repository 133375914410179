export default {
  state: () => ({
    page: 1
  }),
  mutations: {
    setCoordinatorPage(state, page) {
      state.page = page
    }
  }
}
