import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'
import { SORTING_TYPES, HTTP_STATUSES } from '@/constants/common'

class ArticlesActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.order_by ?? 'published_at',
      order_dir: query?.order_dir ?? SORTING_TYPES.DESC,
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/blog-articles`, {
        params,
      }),
    )
  }

  getOne = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/blog-articles/${id}`),
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/blog-articles`, data),
      HTTP_STATUSES.CREATED,
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/blog-articles/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/blog-articles/${id}`),
      HTTP_STATUSES.NO_CONTENT,
      true,
    )
  }
}

export default new ArticlesActions()
