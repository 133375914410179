import AddwineCore from './AddwineCore'
import ProductServiceCore from './ProductServiceCore'
import StorageServiceCore from './StorageServiceCore'
import PaymentServiceCore from './PaymentServiceCore'
import ContentServiceCore from './ContentServiceCore'
import NotificationServiceCore from './NotificationServiceCore'
import AccountServiceCore from './AccountServiceCore'
import YandexAPI from './YandexAPI'
import LandingServiceCore from './LandingServiceCore'
import AddeventServiceCore from './AddeventServiceCore'

class DeliveryLayer {
  AddwineCore = AddwineCore
  LandingsCore = LandingServiceCore
  ProductsCore = ProductServiceCore
  StorageServiceCore = StorageServiceCore
  PaymentServiceCore = PaymentServiceCore
  ContentServiceCore = ContentServiceCore
  NotificationServiceCore = NotificationServiceCore
  AccountsCore = AccountServiceCore
  YandexAPI = YandexAPI
  AddeventServiceCore = AddeventServiceCore
}

export default new DeliveryLayer()
