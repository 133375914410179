import ApiConnector from '../connector'

class ChatsActions {
  getList = async (page = 1, limit = 20) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get('/v1/admin/messages', {
        params: { page, limit }
      })

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  getCoordinatorList = async (page = 1, limit = 20) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        '/v1/admin/messages/coordinator',
        {
          params: { page, limit }
        }
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  getMessageList = async (page = 1, limit = 50, dealerId) => {
    const result = {
      value: false,
      error: null
    }

    const params = { page, limit }

    try {
      const response = await ApiConnector.connector.get(
        '/v1/admin/messages/' + dealerId,
        {
          params
        }
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again later')
    }

    return result
  }

  putFile = async (dealerId, file) => {
    const result = {
      value: false,
      error: null
    }

    const data = new FormData()
    data.append('file', file)

    try {
      const response = await ApiConnector.connector.post(
        `/v1/admin/chats/${dealerId}/file`,
        data
      )

      if (response.status === 201) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new ChatsActions()
