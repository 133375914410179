export const SORTING_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const SORTING_FIELDS = {
  NAME: 'name',
  CREATED_AT: 'created_at',
}

export const EL_TABLE_SORTING_TYPES = {
  ascending: SORTING_TYPES.ASC,
  descending: SORTING_TYPES.DESC,
}
export const MODAL_TYPES = {
  CREATE: 'create',
  EDIT: 'edit',
}

export const HTTP_STATUSES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
}

export const DAY_IN_MS = 24 * 60 * 60 * 1000
