import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'
import { HTTP_STATUSES } from '@/constants/common'

class SettingsActions {
  getList = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/settings`),
      200,
      true,
    )
  }

  update = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/settings`, data),
      HTTP_STATUSES.CREATED,
    )
  }
}

export default new SettingsActions()
