import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'
import { HTTP_STATUSES } from '@/constants/common'

class FilesActions {
  upload = async (file) => {
    const result = {
      value: false,
      error: null,
    }

    const data = new FormData()
    data.append('file', file)

    try {
      const response = await ApiConnector.connector.post('/v2/files/temp', data)

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  delete = async (file) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/files?source=${file}`),
      HTTP_STATUSES.NO_CONTENT,
    )
  }
}

export default new FilesActions()
