export const transformFiltersToQuery = (filters) => {
  const query = {}

  if (filters.search.trim()) {
    query.search = filters.search.trim()
  }

  if (filters.havePresents) {
    query.havePresents = filters.havePresents
  }

  if (filters.haveExpressDelivery) {
    query.have_express_delivery = filters.haveExpressDelivery
  }

  if (filters.isActive) {
    query.isActive = filters.isActive
  }

  if (filters.isAvailable) {
    query.isAvailable = filters.isAvailable
  }

  if (filters.brands?.length) {
    query.brands = filters.brands.join(',')
  }

  if (filters.assortments?.length) {
    query.assortments = filters.assortments.join(',')
  }

  if (filters.categories?.length) {
    query.categories = filters.categories.join(',')
  }

  return query
}

export const parseProductPresence = (presence) => {
  const res = {
    showroomStateText: 'Нет в наличии в шоуруме',
    deliveryText: 'Нет в наличии',
    selfDeliveryText: 'Нет в наличии'
  }

  const presences = {
    UNAVAILABLE: 'UNAVAILABLE',
    MAIN_WAREHOUSE: 'MAIN_WAREHOUSE',
    REMOTE_WAREHOUSE: 'REMOTE_WAREHOUSE',
    SHOWROOM: 'SHOWROOM'
  }

  const currentDay = new Date().getDay(),
    currentHour = new Date().getHours()
  let isAvailable = true

  switch (presence) {
    case presences.SHOWROOM:
      res.showroomStateText = 'В наличии в шоуруме'
      break
    case presences.MAIN_WAREHOUSE:
      res.showroomStateText = 'В наличии в шоуруме'
      break
    default:
      isAvailable = presence === presences.REMOTE_WAREHOUSE
  }

  if (isAvailable) {
    if (presence === presences.REMOTE_WAREHOUSE) {
      switch (true) {
        case currentDay === 0:
          res.deliveryText = 'Доставим послезавтра'
          res.selfDeliveryText = 'Самовывоз послезавтра'
          break
        case currentDay === 4 && currentHour >= 14:
          res.deliveryText = 'Доставим через 3 дня'
          res.selfDeliveryText = 'Самовывоз через 3 дня'
          break
        case currentDay === 5 && currentHour < 14:
          res.deliveryText = 'Доставим через 2 дня'
          res.selfDeliveryText = 'Самовывоз через 2 дня'
          break
        case currentDay === 5 && currentHour >= 14:
          res.deliveryText = 'Доставим через 3 дня'
          res.selfDeliveryText = 'Самовывоз через 3 дня'
          break
        case currentDay === 6:
          res.deliveryText = 'Доставим через 2 дня'
          res.selfDeliveryText = 'Самовывоз через 2 дня'
          break
        default:
          if (currentHour < 14) {
            res.deliveryText = 'Доставим завтра'
            res.selfDeliveryText = 'Самовывоз завтра c 17 до 20'
          } else {
            res.deliveryText = 'Доставим послезавтра'
            res.selfDeliveryText = 'Самовывоз послезавтра'
          }
      }
    } else {
      switch (true) {
        case currentDay === 5 && currentHour >= 20:
          res.deliveryText = 'Доставим послезавтра'
          res.selfDeliveryText = 'Самовывоз послезавтра'
          break
        case currentDay === 6:
          res.deliveryText = 'Доставим завтра'
          res.selfDeliveryText = 'Самовывоз завтра'
          break
        case currentHour < 9:
          res.deliveryText = 'Доставим сегодня после 09:00'
          res.selfDeliveryText = 'самовывоз сегодня с 09:00 до 20:00'
          break
        case currentHour < 18:
          res.deliveryText = 'Доставим через 2-3 часа'
          res.selfDeliveryText = 'Самовывоз сейчас'
          break
        case currentHour < 20:
          res.deliveryText = 'Доставим завтра'
          res.selfDeliveryText = 'Самовывоз сейчас'
          break
        default:
          res.deliveryText = 'Доставим завтра'
          res.selfDeliveryText = 'Самовывоз завтра'
      }
    }
  }

  return res
}
