import connector from '@/delivery/AccountServiceCore'
import UsersActions from '@/delivery/AccountServiceCore/UsersActions'
import SubscriptionsActions from '@/delivery/AccountServiceCore/SubscriptionsActions'
import BonusTransactionsActions from '@/delivery/AccountServiceCore/BonusTransactionsActions'
import NotificationsSettingsActions from '@/delivery/AccountServiceCore/NotificationsSettingsActions'

class AccountServiceCore {
  connector = connector
  UsersActions = UsersActions
  SubscriptionsActions = SubscriptionsActions
  BonusTransactionsActions = BonusTransactionsActions
  NotificationsSettingsActions = NotificationsSettingsActions
}

export default new AccountServiceCore()
