import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class ProductsActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
    }

    if (query?.orderBy) {
      params.order_by = query.orderBy
    }

    if (query?.orderDir) {
      params.order_dir = query.orderDir
    }

    if (query?.search) {
      params.search = query.search
    }

    if (query?.category) {
      params.category = query.category
    }

    if (query?.categoryId) {
      params.category_id = query.categoryId
    }

    if (query?.excludedCategory) {
      params.excluded_category = query.excludedCategory
    }

    if (query?.brandsIds) {
      params.brands_ids = query.brandsIds.join(',')
    }

    if (query?.categoriesIds) {
      params.categories_ids = query.categoriesIds.join(',')
    }

    if (query?.isActive) {
      params.is_active = query.isActive
    }

    if (query?.reservationStart) {
      params.reservation_start_at = query.reservationStart
    }

    if (query?.reservationEnd) {
      params.reservation_end_at = query.reservationEnd
    }

    if (query?.productIds?.length) {
      params.product_ids = query.productIds.join(',')
    }

    if (query?.inStock) {
      params.in_stock = query.inStock
    }

    if (query?.minPrice) {
      params.min_price = query.minPrice
    }

    if (query?.maxPrice) {
      params.max_price = query.maxPrice
    }

    if (query?.volumes) {
      params.volumes = query.volumes
    }

    if(query?.isWithRelatedProducts) {
      params.is_with_related_products = query.isWithRelatedProducts
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/products`, {
        params,
      }),
    )
  }

  getOne = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/products/${id}`),
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/products`, data),
      201,
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/products/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/products/${id}`),
      204,
    )
  }
}

export default new ProductsActions()
