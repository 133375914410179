import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class SubscriptionsActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      with_users: query?.withUsers || false,
      search: query?.search || '',
      type: query?.type || '',
      product_id: query?.productId || '',
      user_id: query?.userId || '',
      order_by: query?.orderBy || 'created_at',
      order_dir: query?.orderDir || 'desc'
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/subscriptions`, {
        params
      })
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/subscriptions/${id}`),
      204
    )
  }
}

export default new SubscriptionsActions()
