import ApiConnector from '../connector'

class FeedbacksActions {
  getList = async (query) => {
    const result = {
      value: false,
      error: null
    }

    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      search: query?.search || null,
      order_by: query?.orderBy || 'created_at',
      order_dir: query?.orderDir || 'desc',
      source: query?.source || null
    }

    try {
      const response = await ApiConnector.connector.get(`/v2/admin/feedback`, {
        params
      })

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new FeedbacksActions()
