import ApiConnector from '../connector'

class SuppliersActions {
  getList = async (query) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(`/v1/admin/suppliers`, {
        params: { limit: query.limit, page: query.page, search: query.search }
      })

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }
    return result
  }
}

export default new SuppliersActions()
