import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class BrandsActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      search: query?.search || null,
      order_by: query?.orderBy || 'order_field',
      order_dir: query?.orderDir || 'desc',
    }

    if (query.bonusProgramId) {
      params.bonus_program_id = query.bonusProgramId
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/brands`, {
        params,
      }),
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/brands/${id}`),
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v1/admin/brands`, data),
      201,
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v1/admin/brands/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v1/admin/brands/${id}`),
      204,
    )
  }
}

export default new BrandsActions()
