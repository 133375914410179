import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class ProductCategoriesActions {
  deleteProductsCategory = async (data) => {
    const params = {
      product_ids: data?.productIds?.join(',') ?? '',
      category_id: data?.categoryId ?? ''
    }
    return await apiRequestWrapper(
      ApiConnector.connector.delete('/v2/admin/product-category/', { params }),
      204
    )
  }
}

export default new ProductCategoriesActions()
