import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class AnalyticsActions {
  getOrdersAnalytics = async (query) => {
    const params = {
      from: query?.from ?? "",
      to: query?.to ?? "",
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/analytics`, {
        params
      })
    )
  }

  getOrdersAnalyticsLandings = async (query) => {
    const params = {
      from: query?.from ?? "",
      to: query?.to ?? "",
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/analytics/landings`, {
        params
      })
    )
  }
}

export default new AnalyticsActions()
