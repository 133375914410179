import axios from "axios";

export default {
  state: () => ({
    product: {
      ID: "",
      brandName: "",
      logotype: File,
      vendorCode: "",
      manufacturer: "",
      appointment: "",
      price: null,
      giftImage: File,
      giftDescription: "",
      mainImage: File,
      gallery: FileList,
      descriptionTitlePrimary: "",
      descriptionTitleSecondary: "",
      descriptionTextPrimary: "",
      descriptionTextSecondary: "",
      countryManufacturer: "",
      model: "",
      suitableFor: "",
      productCategory: "",
      wineCategory: "",
      instructions: File,
      productColor: "",
      structure: "",
      volume: null,
      height: null,
      characteristics: "",
      equipment: "",
    },
    products: [],
  }),

  mutations: {
    setBrandName(state, brandName) {
      state.product.brandName = brandName;
    },

    setLogotype(state, logotype) {
      state.product.logotype = logotype;
    },

    setVendorCode(state, vendorCode) {
      state.product.vendorCode = vendorCode;
    },

    setManufacturer(state, manufacturer) {
      state.product.manufacturer = manufacturer;
    },

    setAppointment(state, appointment) {
      state.product.appointment = appointment;
    },

    setPrice(state, price) {
      state.product.price = price;
    },

    setGiftImage(state, giftImage) {
      state.product.giftImage = giftImage;
    },

    setGiftDescriptions(state, giftDescription) {
      state.product.giftDescription = giftDescription;
    },

    setMainImage(state, mainImage) {
      state.product.mainImage = mainImage;
    },

    setGallery(state, gallery) {
      state.product.gallery = gallery;
    },

    setDescriptionTitlePrimary(state, descriptionTitlePrimary) {
      state.product.descriptionTitlePrimary = descriptionTitlePrimary;
    },

    setDescriptionTitleSecondary(state, descriptionTitleSecondary) {
      state.product.descriptionTitleSecondary = descriptionTitleSecondary;
    },

    setDescriptionTextPrimary(state, descriptionTextPrimary) {
      state.product.descriptionTextPrimary = descriptionTextPrimary;
    },

    setDescriptionTextSecondary(state, descriptionTextSecondary) {
      state.product.descriptionTextSecondary = descriptionTextSecondary;
    },

    setCountryManufacturer(state, countryManufacturer) {
      state.product.countryManufacturer = countryManufacturer;
    },

    setModel(state, model) {
      state.product.model = model;
    },

    setSuitableFor(satte, suitableFor) {
      satte.product.suitableFor = suitableFor;
    },

    setProductCategory(state, productCategory) {
      state.product.productCategory = productCategory;
    },

    setWineCategory(state, wineCategory) {
      state.product.wineCategory = wineCategory;
    },

    setInstructions(state, instructions) {
      state.product.instructions = instructions;
    },

    setProductColor(state, productColor) {
      state.product.productColor = productColor;
    },

    setStructure(state, structure) {
      state.product.structure = structure;
    },

    setVolume(state, volume) {
      state.product.volume = volume;
    },

    setHeight(state, height) {
      state.product.height = height;
    },

    setCharacteristics(state, characteristics) {
      state.product.characteristics = characteristics;
    },

    setEquipment(state, equipment) {
      state.product.equipment = equipment;
    },

    clearProduct(state) {
      (state.product.brandName = ""),
        (state.product.logotype = File),
        (state.product.vendorCode = ""),
        (state.product.manufacturer = ""),
        (state.product.appointment = ""),
        (state.product.price = null),
        (state.product.giftImage = File),
        (state.product.giftDescription = ""),
        (state.product.mainImage = File),
        (state.product.gallery = FileList),
        (state.product.descriptionTitlePrimary = ""),
        (state.product.descriptionTitleSecondary = ""),
        (state.product.descriptionTextPrimary = ""),
        (state.product.descriptionTextSecondary = ""),
        (state.product.countryManufacturer = ""),
        (state.product.model = ""),
        (state.product.suitableFor = ""),
        (state.product.productCategory = ""),
        (state.product.wineCategory = ""),
        (state.product.instructions = File),
        (state.product.productColor = ""),
        (state.product.structure = ""),
        (state.product.volume = null),
        (state.product.height = null),
        (state.product.characteristics = ""),
        (state.product.equipment = "");
    },

    deleteProduct(state, product) {
      state.products.splice(
        state.products.findIndex((p) => p.ID === product.ID),
        1
      );
    },

    getProduct(state, response) {
      (state.product.ID = response.ID),
        (state.product.brandName = response.Name),
        (state.product.logotype = response.Logotype),
        (state.product.vendorCode = response.VendorCode),
        (state.product.manufacturer = response.Manufacturer),
        (state.product.appointment = response.Appointment),
        (state.product.price = response.Price),
        // (state.product.giftImage = File),
        (state.product.giftDescription = response.GiftDescription),
        // (state.product.mainImage = File),
        // (state.product.gallery = FileList),
        (state.product.descriptionTitlePrimary =
          response.DescriptionTitlePrimary),
        (state.product.descriptionTitleSecondary =
          response.DescriptionTitleSecondary),
        (state.product.descriptionTextPrimary =
          response.DescriptionTextPrimary),
        (state.product.descriptionTextSecondary =
          response.DescriptionTextSecondary),
        (state.product.countryManufacturer = response.CountryManufacturer),
        (state.product.model = response.ModelProduct),
        (state.product.suitableFor = response.SuitableFor),
        (state.product.productCategory = response.ProductCategory),
        (state.product.wineCategory = response.WineCategory),
        // (state.product.instructions = File),
        (state.product.productColor = response.ProductColor),
        (state.product.structure = response.Structure),
        (state.product.volume = response.Volume),
        (state.product.height = response.Height),
        (state.product.characteristics = response.Characteristics),
        (state.product.equipment = response.Equipment);
    },

    getProducts(state, response) {
      state.products = response.data.result;
    },
  },

  getters: {
    allProducts(state) {
      return state.products;
    },
  },

  actions: {
    getAllProducts({ commit }) {
      axios
        .get("/api/v1/products")
        .then((response) => commit("getProducts", response))
        .catch((error) => {
          console.log(error);
        });
    },

    getProductById({ commit }, product) {
      axios
        .get("/api/v1/products/byId/" + product.ID)
        .then((response) => commit("getProduct", response.data.result))
        .catch((error) => {
          console.log(error);
        });
    },

    postProduct({ state }) {
      const value = {
        Name: state.product.brandName,
        Logotype: state.product.logotype,
        VendorCode: state.product.vendorCode,
        Manufacturer: state.product.manufacturer,
        Appointment: state.product.appointment,
        Price: state.product.price,
        // GiftImage: state.product.giftImage,
        GiftDescription: state.product.giftDescription,
        // MainImage: state.product.mainImage,
        // Images: state.product.gallery,
        DescriptionTitlePrimary: state.product.descriptionTitlePrimary,
        DescriptionTitleSecondary: state.product.descriptionTitleSecondary,
        DescriptionTextPrimary: state.product.descriptionTextPrimary,
        DescriptionTextSecondary: state.product.descriptionTextSecondary,
        CountryManufacturer: state.product.countryManufacturer,
        ModelProduct: state.product.model,
        SuitableFor: state.product.suitableFor,
        ProductCategory: state.product.productCategory,
        WineCategory: state.product.wineCategory,
        Instructions: state.product.instructions,
        ProductColor: state.product.productColor,
        Structure: state.product.structure,
        Volume: state.product.volume,
        Height: state.product.height,
        Characteristics: state.product.characteristics,
        Equipment: state.product.equipment,
      };
      axios
        .post("/api/v1/products", value)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    putProduct({ state }) {
      const value = {
        Name: state.product.brandName,
        Logotype: state.product.logotype,
        VendorCode: state.product.vendorCode,
        Manufacturer: state.product.manufacturer,
        Appointment: state.product.appointment,
        Price: state.product.price,
        // GiftImage: state.product.giftImage,
        GiftDescription: state.product.giftDescription,
        // MainImage: state.product.mainImage,
        // Images: state.product.gallery,
        DescriptionTitlePrimary: state.product.descriptionTitlePrimary,
        DescriptionTitleSecondary: state.product.descriptionTitleSecondary,
        DescriptionTextPrimary: state.product.descriptionTextPrimary,
        DescriptionTextSecondary: state.product.descriptionTextSecondary,
        CountryManufacturer: state.product.countryManufacturer,
        ModelProduct: state.product.model,
        SuitableFor: state.product.suitableFor,
        ProductCategory: state.product.productCategory,
        WineCategory: state.product.wineCategory,
        Instructions: state.product.instructions,
        ProductColor: state.product.productColor,
        Structure: state.product.structure,
        Volume: state.product.volume,
        Height: state.product.height,
        Characteristics: state.product.characteristics,
        Equipment: state.product.equipment,
      };
      axios
        .put("/api/v1/products/" + state.product.ID, value)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteProduct({ commit }, product) {
      commit("deleteProduct", product);
      axios
        .delete("/api/v1/products/" + product.ID)
        .then((response) => console.log(response))
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
