import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class FAQCategoriesActions {
  getList = async (query) => {
    
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      with_pagination: query?.withPagination || 'true',
      with_faqs: query?.withFaqs || 'true',
      order_by: query?.orderBy || 'order_field'
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/faq-categories`, {
        params
      })
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post('/v2/admin/faq-categories', data),
      201
    )
  }

  updateByID = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/faq-categories/${id}`, data)
    )
  }

  update = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/faq-categories`, data), 
      204
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/faq-categories/${id}`),
      204
    )
  }
}

export default new FAQCategoriesActions()
