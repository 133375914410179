import ApiConnector from '../connector'

class AttributeValueOptionsActions {
  delete = async (data) => {
    const result = {
      value: false,
      error: null
    }

    const params = {
      attribute_value_id: data.attributeValueId,
      attribute_option_id: data.attributeOptionId
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v2/admin/attribute-value-options`,
        { params }
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new AttributeValueOptionsActions()
