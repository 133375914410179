import ApiConnector from '../connector'

class SellersSettingsActions {
  get = async () => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        '/v1/admin/sellers-settings'
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  update = async data => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.put(
        '/v1/admin/sellers-settings',
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new SellersSettingsActions()
