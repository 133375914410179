import connector from './connector'
import AdPromosActions from './AdPromosActions'
import GiftPromosActions from './GiftPromosActions'
import PaymentTypesActions from './PaymentTypesActions'
import GeneralOrdersActions from './GeneralOrdersActions'
import DeliveryPricesActions from './DeliveryPricesActions'
import DeliveryParamsActions from './DeliveryParamsActions'
import PaymentSettingsActions from './PaymentSettingsActions'
import OrderStatesActions from './OrderStatesActions'
import PaymentStatusesActions from './PaymentStatusesActions'
import DeliverySettingsActions from './DeliverySettingsActions'
import PromoActions from './PromoActions'
import AnalyticsActions from './AnalyticsActions'
class PaymentServiceCore {
  connector = connector
  AdPromosActions = AdPromosActions
  GiftPromosActions = GiftPromosActions
  PaymentTypesActions = PaymentTypesActions
  GeneralOrdersActions = GeneralOrdersActions
  DeliveryPricesActions = DeliveryPricesActions
  DeliveryParamsActions = DeliveryParamsActions
  PaymentSettingsActions = PaymentSettingsActions
  OrderStatesActions = OrderStatesActions
  PaymentStatusesActions = PaymentStatusesActions
  DeliverySettingsActions = DeliverySettingsActions
  PromoActions = PromoActions
  AnalyticsActions = AnalyticsActions
}

export default new PaymentServiceCore()
