import { WAREHOUSES } from '@/constants/warehouses'

const formatDate = (date) => {
  const options = { day: 'numeric', month: 'numeric', year: '2-digit' }
  return new Date(date).toLocaleDateString('ru', options)
}

const formatTime = (date) => {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
  }

  return new Date(date).toLocaleString('ru', options)
}

const formatDateFullYear = (date) => {
  const options = { day: 'numeric', month: 'numeric', year: 'numeric' }
  return new Date(date).toLocaleDateString('ru', options)
}

const formatDateTime = (date) => {
  const options = {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }

  return new Date(date).toLocaleString('ru', options)
}

const formatInputDate = (date) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  return new Date(date)
    .toLocaleDateString('ru', options)
    .replaceAll('.', '-')
    .split('-')
    .reverse()
    .join('-')
}

const formateStatusOrder = (status) => {
  switch (status) {
    case 'DRAFT':
      return 'Предварительная'
    case 'PROCESSING':
      return 'Заявка принята'
    case 'CONFIRMED':
      return 'Заказ подтвержден'
    case 'ASSEMBLY':
      return 'Сбор заказа'
    case 'SELFDELIVERY':
      return 'Самовывоз'
    case 'DELIVERY':
      return 'Доставка'
    case 'REGION_DELIVERY':
      return 'Доставка ТК'
    case 'DELIVERED':
      return 'Заказ доставлен'
    case 'SUCCESS':
      return 'Успешно реализован'
    case 'FAILED':
      return 'Провал'
    default:
      return 'Заявка принята'
  }
}

const formatWarehouse = (warehouse) => {
  switch (warehouse) {
    case WAREHOUSES.IE:
      return 'ИП Оборочану'
    case WAREHOUSES.LLC:
      return 'ООО Добавь Вина'
    case WAREHOUSES.RW:
      return 'Удаленнный склад'
    case WAREHOUSES.RWC:
      return 'Коммиссионный склад'
    default:
      return 'Ошибка'
  }
}

const getNextActiveElement = (
  list,
  activeElement,
  shouldGetNext,
  isCycleAllowed,
) => {
  let index = list.indexOf(activeElement)
  if (index === -1) {
    return list[!shouldGetNext && isCycleAllowed ? list.length - 1 : 0]
  }

  const listLength = list.length
  index += shouldGetNext ? 1 : -1

  if (isCycleAllowed) {
    index = (index + listLength) % listLength
  }

  return list[Math.max(0, Math.min(index, listLength - 1))]
}

const getStringEnding = (number, endsList) => {
  const cases = [2, 0, 1, 1, 1, 2]
  return `${
    endsList[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ]
  }`
}

export {
  formatDate,
  formatTime,
  formatDateFullYear,
  formatDateTime,
  formateStatusOrder,
  formatInputDate,
  getNextActiveElement,
  formatWarehouse,
  getStringEnding,
}
