
const state = {
    scrollY: 0
  };
  
  const mutations = {
    setScrollY(state, scrollY) {
      state.scrollY = scrollY;
    }
  };
  
  const actions = {
    scrollY({ commit }, scrollY) {
      commit('setScrollY', scrollY);
    }
  };
  
  const getters = {
    getScrollY: state => state.scrollY
  };
  
  export default {
    state,
    mutations,
    actions,
    getters
  };
  