import ApiConnector from '../connector'

class SubscriptionActions {
  getList = async (query) => {
    const result = {
      value: false,
      error: null
    }

    const defaultQuery = {
      page: 1,
      limit: 20,
      productId: null,
      dealerAccountId: null
    }

    const params = { ...defaultQuery, ...query }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/subscriptions`,
        { params }
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new SubscriptionActions()
