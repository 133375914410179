import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import AddwineRoutes from './projects/addwine'
import SellersRoutes from './projects/sellers'
import LandingRoutes from './projects/landing'
import AddeventRoutes from './projects/addevent'

import isAuth from './middleware/isAuth'
import isGuest from './middleware/isGuest'

import hasCoordinatorAccess from './middleware/hasCoordinatorAccess'
import middlewarePipeline from './middleware/middlewarePipeline'
import hasRole from './middleware/hasRole'
import { ACCESS_MAP, PROFILE_ROUTES } from '../constants/routing'
import { DEFAULT_ROLES } from '../constants/roles'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
      meta: {
        middleware: [isAuth],
        layout: 'auth-layout',
      },
    },
    {
      path: '/sign-in',
      name: 'SignIn',
      component: () => import('@/views/sellers/SignIn.vue'),
      meta: {
        middleware: [isGuest],
      },
    },
    {
      path: '/chats',
      component: () => import('@/views/sellers/coordinator-chats/Index.vue'),
      children: [
        {
          path: '',
          name: 'coordinatorChats',
          component: () => import('@/views/sellers/coordinator-chats/List.vue'),
          meta: {
            middleware: [isAuth, hasCoordinatorAccess],
            layout: 'auth-layout',
          },
        },
        {
          path: ':id',
          name: 'coordinatorChat',
          component: () => import('@/views/sellers/coordinator-chats/Chat.vue'),
          meta: {
            middleware: [isAuth, hasCoordinatorAccess],
          },
        },
      ],
    },
    {
      path: '/managers',
      component: () => import('@/views/managers/Index.vue'),
      meta: {
        middleware: [isAuth],
      },
      children: [
        {
          path: '',
          name: 'managers',
          component: () => import('@/views/managers/List.vue'),
          meta: {
            middleware: [isAuth],
            layout: 'auth-layout',
          },
        },
        {
          path: 'create',
          name: 'createManager',
          component: () => import('@/views/managers/Create.vue'),
          meta: {
            middleware: [isAuth],
            layout: 'auth-layout',
          },
        },
        {
          path: 'edit/:id',
          name: 'editManager',
          component: () => import('@/views/managers/Edit.vue'),
          meta: {
            middleware: [isAuth],
            layout: 'auth-layout',
          },
        },
      ],
    },
    {
      path: PROFILE_ROUTES.INDEX,
      name: 'profile',
      component: () => import('@/views/profile/Index.vue'),
      meta: {
        middleware: [isAuth],
        layout: 'auth-layout',
      },
    },
    LandingRoutes,
    SellersRoutes,
    AddwineRoutes,
    AddeventRoutes,
  ],
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  let middleware = to.meta.middleware
  if (to.matched?.length > 1) {
    let route = to.matched[to.matched.length - 1]?.path
    //rm slash on some routes
    if (route.slice(-1) === '/') {
      route = route.slice(0, -1)
    }
    middleware = [...middleware, hasRole(ACCESS_MAP[route] ?? DEFAULT_ROLES)]
  }
  const context = {
    to,
    from,
    next,
    store,
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

export default router
