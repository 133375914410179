export default {
  state: () => ({
    page: 1,
  }),
  mutations: {
    setPage(state, page) {
      state.page = page
    },
  },
}
