import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'
import { SORTING_TYPES, HTTP_STATUSES } from '@/constants/common'

class ArticleCategoriesActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.order_by ?? 'name',
      order_dir: query?.order_dir ?? SORTING_TYPES.DESC,
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/article-categories`, {
        params,
      }),
    )
  }

  getOne = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/article-categories/${id}`),
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/article-categories`, data),
      HTTP_STATUSES.CREATED,
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/article-categories/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/article-categories/${id}`),
      HTTP_STATUSES.NO_CONTENT,
      true,
    )
  }
}

export default new ArticleCategoriesActions()
