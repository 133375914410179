import ApiConnector from '../connector'

class BlogArticles {
  getList = async (query) => {
    const result = {
      value: false,
      error: null
    }
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      search: query?.search || null,
      order_by: query?.orderBy || 'created_at',
      order_dir: query?.orderDir || 'desc'
    }
    try {
      const response = await ApiConnector.connector.get(
        `/v2/admin/blog-articles`,
        {
          params
        }
      )
      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }
    return result
  }

  getById = async (id) => {
    const result = {
      value: false,
      error: null
    }
    try {
      const response = await ApiConnector.connector.get(
        `/v2/admin/blog-articles/${id}`
      )
      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }
    return result
  }

  create = async (data) => {
    const result = {
      value: false,
      error: null
    }
    try {
      const response = await ApiConnector.connector.post(
        '/v2/admin/blog-articles/',
        data
      )
      if (response.status === 201) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again later')
    }
    return result
  }

  update = async (id, data) => {
    const result = {
      value: false,
      error: null
    }
    try {
      const response = await ApiConnector.connector.put(
        `/v2/admin/blog-articles/${id}`,
        data
      )
      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }
    return result
  }

  delete = async (id) => {
    const result = {
      value: false,
      error: null
    }
    try {
      const response = await ApiConnector.connector.delete(
        `/v2/admin/blog-articles/${id}`
      )
      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }
    return result
  }

  deleteCategoryFromArticle = async (articleId, categoryId) => {
    const result = {
      error: null
    }
    const params = {
      blog_article_id: articleId,
      category_id: categoryId
    }
    try {
      const response = await ApiConnector.connector.delete(
        `/v2/admin/blog-articles-categories`,
        { params }
      )
      if (response.status !== 204)
        result.error = new Error(`API error, status: ${response.status}`)
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }
    return result
  }
}

export default new BlogArticles()
