import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class ArticleCategoriesActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      search: query?.search || null
    }
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/article-categories`, {
        params
      })
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post('/v2/admin/article-categories/', data),
      201
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/article-categories/${id}`, data),
      200
    )
  }

  getOne = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/article-categories/${id}`)
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/article-categories/${id}`),
      204
    )
  }

  deleteSeoText = async (id, seoId) => {
    const params = {
      category_id: id,
      seo_text_id: seoId
    }
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/article-categories-seo-texts`, {
        params
      }),
      204
    )
  }

  deleteSeoFaq = async (id, seoId) => {
    const params = {
      category_id: id,
      seo_faq_id: seoId
    }
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/article-categories-seo-faqs`, {
        params
      }),
      204
    )
  }
}

export default new ArticleCategoriesActions()
