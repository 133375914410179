import ApiConnector from '../connector'

class PagesActions {
  getList = async (project) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v2/admin/pages/list?project=` + project
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  get = async (slug, project) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v2/admin/pages?slug=` + slug + `&project=` + project
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
  update = async (slug, project, data) => {
    const result = {
      value: false,
      error: null
    }
    try {
      const response = await ApiConnector.connector.post(
        '/v2/admin/pages?slug=' + slug + `&project=` + project,
        data
      )

      if (response.status === 201) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again later')
    }

    return result
  }
}

export default new PagesActions()
