import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class MailActions {
  getOrderTemplatesList = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get('/v1/mail/order-templates')
    )
  }

  updateOrCreateOrderTemplateByType = async (type, body, project) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`v1/mail/order-templates/byType/${type}`, {
        type,
        body,
        project
      })
    )
  }
}

export default new MailActions()
