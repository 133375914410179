import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class OrderStatesActions {
  synchronize = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(
        `/v2/admin/orders/payment-statuses/synchronization`
      )
    )
  }
  getList = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get('/v2/admin/orders/payment-statuses')
    )
  }
  remove = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/orders/payment-statuses/${id}`),
      204
    )
  }
  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.patch(
        `/v2/admin/orders/payment-statuses/${id}`,
        data
      ),
      200
    )
  }
}

export default new OrderStatesActions()
