export const ROLES = {
  OWNER: 'OWNER',
  MANAGER: 'MANAGER',
  REMOTE_MANAGER: 'REMOTE_MANAGER',
  ADMIN: 'ADMIN'
}

export const TRANSLATED_ROLES = {
  [ROLES.OWNER]: 'Владелец',
  [ROLES.MANAGER]: 'Менеджер',
  [ROLES.REMOTE_MANAGER]: 'Удаленный менеджер',
  [ROLES.ADMIN]: 'Администратор'
}

export const ADDITIONAL_ROLES = {
  COORDINATOR: 'COORDINATOR',
  DEFAULT_COORDINATOR: 'DEFAULT_COORDINATOR',
  DEFAULT_MANAGER: 'DEFAULT_MANAGER'
}

export const TRANSLATED_ADDITIONAL_ROLES = {
  [ADDITIONAL_ROLES.COORDINATOR]: 'Координатор',
  [ADDITIONAL_ROLES.DEFAULT_COORDINATOR]: 'Стандартный координатор',
  [ADDITIONAL_ROLES.DEFAULT_MANAGER]: 'Стандартный менеджер'
}

export const DEFAULT_ROLES = [ROLES.ADMIN, ROLES.MANAGER, ROLES.OWNER]
