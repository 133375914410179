import isAuth from '../middleware/isAuth'
import hasSellersAccess from '../middleware/hasSellersAccess'
import { ADDSELLERS_ADMIN_ROUTES } from '../../constants/routing'

const metaData = {
  middleware: [isAuth, hasSellersAccess],
  layout: 'auth-layout',
}

export default {
  path: '/sellers',
  component: () => import('@/views/sellers/Index.vue'),
  meta: metaData,
  children: [
    {
      path: '',
      name: 'SellersMenu',
      component: () => import('@/views/sellers/Home.vue'),
      meta: metaData,
    },
    {
      path: 'settings',
      name: 'SellersSettings',
      component: () => import('@/views/sellers/Settings.vue'),
      meta: metaData,
    },
    {
      path: 'client-requesites',
      component: () => import('@/views/sellers/client-requesites/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersClientRequesite',
          component: () => import('@/views/sellers/client-requesites/List.vue'),
          meta: metaData,
        },

        {
          path: ':id',
          name: 'editClientRequesite',
          component: () => import('@/views/sellers/client-requesites/Edit.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDSELLERS_ADMIN_ROUTES.ORDERS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'orders',
          component: () => import('@/views/sellers/orders/Home.vue'),
          meta: metaData,
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'sellersOrders',
              component: () =>
                import('@/views/sellers/orders/addsellers/List.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.GET_ONE_PATH,
              name: 'sellersOrder',
              component: () =>
                import('@/views/sellers/orders/addsellers/View.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.EDIT_PATH,
              name: 'editSellersOrder',
              component: () =>
                import('@/views/sellers/orders/addsellers/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.ORDERS.OFFSELLERS.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'offsellersOrders',
              component: () =>
                import('@/views/sellers/orders/offsellers/List.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.GET_ONE_PATH,
              name: 'offsellersOrder',
              component: () =>
                import('@/views/sellers/orders/offsellers/View.vue'),
              meta: metaData,
            },
          ],
        },
      ],
    },
    {
      path: 'dealers',
      component: () => import('@/views/sellers/dealers/Index'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersDealers',
          component: () => import('@/views/sellers/dealers/Home.vue'),
          meta: metaData,
        },
        {
          path: 'bonus-count',
          name: 'sellersDealersBonusCountList',
          component: () =>
            import('@/views/sellers/dealers/bonus-count/Index.vue'),
          meta: metaData,
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.DEALERS.NOTES.PATH,
          name: 'sellersDealersNotes',
          component: () => import('@/views/sellers/dealers/notes/Index.vue'),
          meta: metaData,
        },
        {
          path: 'list',
          name: 'sellersDealersList',
          component: () => import('@/views/sellers/dealers/list/List.vue'),
          meta: metaData,
        },
        {
          path: 'create-account',
          name: 'sellersCreateDealers',
          component: () => import('@/views/sellers/dealers/list/Create.vue'),
          meta: metaData,
        },
        {
          path: 'edit-account/:id',
          name: 'sellersEditDealers',
          component: () => import('@/views/sellers/dealers/list/Edit.vue'),
          meta: {
            middleware: [isAuth],
            layout: 'auth-layout',
          },
        },
        {
          path: ':id/orders',
          name: 'sellersDealersOrders',
          component: () => import('@/views/sellers/dealers/Index'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'sellersDealersOrders',
              component: () =>
                import('@/views/sellers/dealers/list/orders/Orders.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.CREATE_PATH,
              name: 'sellersDealersOrdersCreate',
              component: () =>
                import('@/views/sellers/dealers/list/orders/Create.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: ':id/client-requesites',
          name: 'sellersClientRequesites',
          component: () => import('@/views/sellers/dealers/Index'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'sellersClientRequesites',
              component: () =>
                import(
                  '@/views/sellers/dealers/list/clientRequesites/ClientRequesites.vue'
                ),
              meta: metaData,
            },
            {
              path: 'create',
              name: 'sellersClientRequesitesCreate',
              component: () =>
                import(
                  '@/views/sellers/dealers/list/clientRequesites/CreateClientRequesites.vue'
                ),
              meta: metaData,
            },
          ],
        },
        {
          path: ':id/delivery-addresses',
          component: () => import('@/views/sellers/dealers/Index'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'sellersDealersDeliveryAddresses',
              component: () =>
                import(
                  '@/views/sellers/dealers/list/deliveryAddresses/DeliveryAddresses.vue'
                ),
              meta: metaData,
            },
            {
              path: 'create',
              name: 'sellersDealersDeliveryAddressesCreate',
              component: () =>
                import(
                  '@/views/sellers/dealers/list/deliveryAddresses/CreateDeliveryAddress.vue'
                ),
              meta: metaData,
            },
            {
              path: ':addressId',
              name: 'sellersDealersDeliveryAddressesUpdate',
              component: () =>
                import(
                  '@/views/sellers/dealers/list/deliveryAddresses/EditDeliveryAddress.vue'
                ),
              meta: metaData,
            },
          ],
        },
        {
          path: ':id/recipients',
          component: () => import('@/views/sellers/dealers/Index'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'sellersDealersRecipients',
              component: () =>
                import('@/views/sellers/dealers/list/recipients/List.vue'),
              meta: metaData,
            },
            {
              path: 'create',
              name: 'sellersDealersRecipientCreate',
              component: () =>
                import('@/views/sellers/dealers/list/recipients/Create.vue'),
              meta: metaData,
            },
            {
              path: ':recipientId',
              name: 'sellersDealersRecipientUpdate',
              component: () =>
                import('@/views/sellers/dealers/list/recipients/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.DEALERS.BONUS_TRANSACTIONS.PATH,
          component: () => import('@/views/sellers/dealers/Index'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'sellersDealersBonusTransactions',
              component: () =>
                import(
                  '@/views/sellers/dealers/list/bonusTransactions/List.vue'
                ),
              meta: metaData,
            },
          ],
        },
      ],
    },
    {
      path: 'downloads',
      component: () => import('@/views/sellers/downloads/Index'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersDownloads',
          component: () => import('@/views/sellers/downloads/List.vue'),
          meta: metaData,
        },
        {
          path: ':id',
          name: 'sellersDownloadsFiles',
          component: () => import('@/views/sellers/downloads/Files.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: 'offers',
      component: () => import('@/views/sellers/offers/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersOffers',
          component: () => import('@/views/sellers/offers/List.vue'),
          meta: metaData,
        },
        {
          path: 'create-offer',
          name: 'sellersCreateOffer',
          component: () => import('@/views/sellers/offers/Create.vue'),
          meta: metaData,
        },
        {
          path: 'edit-offer/:id',
          name: 'sellersEditOffer',
          component: () => import('@/views/sellers/offers/Edit.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: 'marketing-notifications',
      component: () =>
        import('@/views/sellers/marketing-notification/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersMarketingNotification',
          component: () =>
            import('@/views/sellers/marketing-notification/List.vue'),
          meta: metaData,
        },
        {
          path: 'create',
          name: 'sellersCreateMarketingNotification',
          component: () =>
            import('@/views/sellers/marketing-notification/Create.vue'),
          meta: metaData,
        },
        {
          path: 'edit/:id',
          name: 'sellersEditMarketingNotification',
          component: () =>
            import('@/views/sellers/marketing-notification/Edit.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: 'suppliers',
      component: () => import('@/views/sellers/suppliers/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersSruppliersCatalogs',
          component: () => import('@/views/sellers/suppliers/List.vue'),
          meta: metaData,
        },
        {
          path: ':id/edit',
          name: 'sellersEditSuppliersCatalog',
          component: () => import('@/views/sellers/suppliers/Edit.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: 'products',
      name: 'sellersProducts',
      component: () => import('@/views/sellers/Products.vue'),
      meta: metaData,
    },
    {
      path: 'catalogs',
      component: () => import('@/views/sellers/catalogs/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersCatalogs',
          component: () => import('@/views/sellers/catalogs/List.vue'),
          meta: metaData,
        },
        {
          path: ':id/edit',
          name: 'sellersEditCatalog',
          component: () => import('@/views/sellers/catalogs/Edit.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: 'instructional-videos',
      component: () => import('@/views/sellers/instructional-videos/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersInstructionalVideos',
          component: () =>
            import('@/views/sellers/instructional-videos/List.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDSELLERS_ADMIN_ROUTES.CHATS.PATH,
      component: () => import('@/views/sellers/chats/Index.vue'),
      children: [
        {
          path: '',
          name: 'sellersChats',
          component: () => import('@/views/sellers/coordinator-chats/List.vue'),
          meta: metaData,
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.GET_ONE_PATH,
          name: 'sellersChat',
          component: () => import('@/views/sellers/chats/Chat.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: 'templates',
      name: 'sellersTemplates',
      component: () => import('@/views/sellers/MailTemplates.vue'),
      meta: metaData,
    },
    {
      path: 'subscriptions',
      component: () => import('@/views/sellers/subscriptions/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersSubscriptions',
          component: () => import('@/views/sellers/subscriptions/List.vue'),
          meta: metaData,
        },
        {
          path: 'dealer-account/:id',
          name: 'sellersDealerAccountSubscriptions',
          component: () =>
            import('@/views/sellers/subscriptions/DealerList.vue'),
          meta: metaData,
        },
        {
          path: 'product/:id',
          name: 'sellersProductSubscriptions',
          component: () =>
            import('@/views/sellers/subscriptions/ProductList.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: 'feedbacks',
      component: () => import('@/views/sellers/feedbacks/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersFeedbacks',
          component: () => import('@/views/sellers/feedbacks/List.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: 'prices-policy',
      component: () => import('@/views/sellers/prices-policy/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersPricesPolicy',
          component: () => import('@/views/sellers/prices-policy/List.vue'),
          meta: metaData,
        },
        {
          path: 'create',
          name: 'sellersCreatePricesPolicy',
          component: () => import('@/views/sellers/prices-policy/Create.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDSELLERS_ADMIN_ROUTES.BRANDS.PATH,
      component: () => import('@/views/sellers/brands/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersBrands',
          component: () => import('@/views/sellers/brands/List.vue'),
          meta: metaData,
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.CREATE_PATH,
          name: 'sellersBrandsCreate',
          component: () => import('@/views/sellers/brands/Create.vue'),
          meta: metaData,
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.EDIT_PATH,
          name: 'sellersBrandsEdit',
          component: () => import('@/views/sellers/brands/Edit.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.PATH,
      component: () => import('@/views/sellers/bonus-program/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersBonusProgram',
          component: () => import('@/views/sellers/bonus-program/List.vue'),
          meta: metaData,
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.CREATE_PATH,
          name: 'sellersBonusProgramCreate',
          component: () => import('@/views/sellers/bonus-program/Create.vue'),
          meta: metaData,
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.EDIT_PATH,
          name: 'sellersBonusProgramEdit',
          component: () => import('@/views/sellers/bonus-program/Edit.vue'),
          meta: metaData,
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.ACTIVE.PATH,
          name: 'sellersBonusProgramActive',
          component: () => import('@/views/sellers/bonus-program/Active.vue'),
          meta: metaData,
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.ARCHIVE.PATH,
          name: 'sellersBonusProgramArchive',
          component: () => import('@/views/sellers/bonus-program/Archive.vue'),
          meta: metaData,
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.EXCLUDE_DEALERS.PATH,
          name: 'sellersBonusProgramExcludeDealers',
          component: () =>
            import('@/views/sellers/bonus-program/ExcludeDealers.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDSELLERS_ADMIN_ROUTES.CONTENT.PATH,
      component: () => import('@/views/sellers/content/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'sellersContentMenu',
          component: () => import('@/views/sellers/content/Home.vue'),
          meta: metaData,
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.PATH,
          component: () => import('@/views/sellers/content/seo-faqs/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'sellersSEOFAQsList',
              component: () =>
                import('@/views/sellers/content/seo-faqs/List.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.CREATE_PATH,
              name: 'sellersSEOFAQsCreate',
              component: () =>
                import('@/views/sellers/content/seo-faqs/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.GET_ONE_PATH,
              name: 'sellersSEOFAQsEdit',
              component: () =>
                import('@/views/sellers/content/seo-faqs/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.PATH,
          component: () => import('@/views/sellers/content/pages/Index.vue'),
          meta: metaData,
          children: [
            {
              path: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.LIST.PATH,
              name: 'sellersPages',
              component: () => import('@/views/sellers/content/pages/List.vue'),
              meta: metaData,
            },
            {
              path: '',
              name: 'sellersPagesMain',
              component: () => import('@/views/sellers/content/pages/Main.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.SUPPLIERS.PATH,
              name: 'sellersPagesSupplier',
              component: () =>
                import('@/views/sellers/content/pages/Supplier.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.RETAILERS.PATH,
              name: 'sellersPagesRetailer',
              component: () =>
                import('@/views/sellers/content/pages/Retailer.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.SITE_SETTINGS.PATH,
              name: 'sellersPagesSettings',
              component: () =>
                import('@/views/sellers/content/pages/Settings.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.CONFIDENTIAL_POLICY
                .PATH,
              name: 'sellersPagesConfidentialPolicy',
              component: () =>
                import('@/views/sellers/content/pages/Policy.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.PUBLIC_OFFER.PATH,
              name: 'sellersPagesPublicOffer',
              component: () =>
                import('@/views/sellers/content/pages/PublicOffer.vue'),
              meta: metaData,
            },
            {
              path: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.BONUS_PROGRAM.PATH,
              name: 'sellersPagesBonusProgram',
              component: () =>
                import('@/views/sellers/content/pages/BonusProgram.vue'),
              meta: metaData,
            },
          ],
        },
      ],
    },
  ],
}
