import { Message } from 'element-ui'

const apiRequestWrapper = async (
  request,
  successStatusCode = 200,
  withoutNotification = false
) => {
  const result = {
    value: false,
    error: null
  }

  try {
    const response = await request

    if (response.status !== successStatusCode) {
      const error = new Error(`API error, status: ${response.status}.`)
      error.response = response
      throw error
    }

    if (
      (response.data.result === null || response.data.result === undefined) &&
      response.status !== 202 &&
      response.status !== 204
    ) {
      throw new Error(`API returned an empty response`)
    }

    result.value = response.data.result
  } catch (e) {
    const detailsMessage = e?.response?.data?.errors?.length
      ? 'Error: ' + e?.response?.data?.errors.join(', ')
      : null
    const errorMessage = e?.response?.data?.error?.message

    result.error =
      errorMessage ||
      detailsMessage ||
      e.message ||
      'Network error, try again late'
    if (!withoutNotification) {
      Message.error(result.error)
    }
  }

  return result
}

export { apiRequestWrapper }
