import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'
import { SORTING_TYPES, HTTP_STATUSES } from '@/constants/common'

class BrandsActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.order_by ?? 'name',
      order_dir: query?.order_dir ?? SORTING_TYPES.DESC
    }

    if (query?.search) {
      params.search = query.search
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/brands`, {
        params
      })
    )
  }
  getOne = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/brands/${id}`)
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/brands`, data),
      HTTP_STATUSES.CREATED
    )
  }

  update = async (data, id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/brands/${id}`, data)
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/brands/${id}`),
      HTTP_STATUSES.NO_CONTENT,
      true,
    )
  }
}

export default new BrandsActions()
