import ApiConnector from '@/delivery/AccountServiceCore/connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class NotificationsSettingsActions {
  get = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/notification-settings`),
    )
  }
  save = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/notification-settings`, data),
    )
  }
}

export default new NotificationsSettingsActions()
