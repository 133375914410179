import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'
import { HTTP_STATUSES } from '@/constants/common'

class PdfCatalogActions {
  getTemplate = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/catalog/pdf-template`),
      HTTP_STATUSES.OK,
      true,
    )
  }

  updateTemplate = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/catalog/pdf-template`, data),
      HTTP_STATUSES.CREATED,
    )
  }
}

export default new PdfCatalogActions()
