import ApiConnector from '../connector'

class SynchronizationActions {
  getXLSX = async (query) => {
    const result = {
      value: false,
      error: null
    }

    const params = {
      search: query?.search?.trim() || '',
      brands: query?.brands?.join(',') || '',
      assortments: query?.assortments?.join(',') || '',
      categories: query?.categories?.join(',') || ''
    }

    if (query.havePresents) {
      params.have_presents = query.havePresents
    }

    if (query.haveExpressDelivery) {
      params.have_express_delivery = query.haveExpressDelivery
    }

    if (query.isActive) {
      params.is_active = query.isActive
    }

    if (query.isAvailable) {
      params.is_available = query.isAvailable
    }

    if (query.supplier) {
      params.supplier = query.supplier
    }

    try {
      const response = await ApiConnector.connector.get(
        '/v2/admin/synchronization/xlsx',
        { params, responseType: 'blob' }
      )
      if (response.status === 200) {
        result.value = response.data
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again later')
    }

    return result
  }
  uploadXLSX = async (file) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const data = new FormData()
      data.append('productsExcel', file)

      const response = await ApiConnector.connector.post(
        '/v2/admin/synchronization/xlsx',
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = e.response.data.error
    }

    return result
  }
}

export default new SynchronizationActions()
