import { apiRequestWrapper } from '@/helpers/delivery'

import axios from 'axios'
import ApiConnector from '../connector'

class SeosActions {
  getRobotsTxtContent = async () => {
    const result = {
      value: null,
      error: null
    }

    try {
      const response = await axios.get(
        `${process.env.VUE_APP_S3_URL}v2/seo/robots.txt`
      )
      if (response.status !== 200) {
        const error = new Error(`API error, status: ${response.status}.`)
        error.response = response
        throw error
      }

      if (!response.data) {
        throw new Error(`API returned an empty response`)
      }

      result.value = response.data
    } catch (e) {
      const errorMessage = e?.response?.data?.error?.message

      result.error = errorMessage
    }

    return result
  }

  updateRobotsTxt = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put('/v2/admin/seo/robots-txt', data),
      204
    )
  }

  getSitemap = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get('/v2/admin/seo/sitemap-settings'),
      200
    )
  }
  updateSitemap = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post('/v2/admin/seo/sitemap-settings', data),
      200
    )
  }
}

export default new SeosActions()
