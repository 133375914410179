import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'
import { PROJECTS } from '@/constants/projects/index.js'
class SeoFaqsActions {
  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post('/v2/admin/seo-faqs', data),
      201
    )
  }

  getList = async (query) => {
    const params = {
      page: query?.page ?? 1,
      limit: query?.limit ?? 20,
      search: query?.search ?? null,
      order_by: query?.order_by ?? 'updated_at',
      order_dir: query?.order_dir ?? 'desc',
      project: query?.project ?? PROJECTS.ADDWINE,
      is_linked: query?.isLinked ?? null
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/seo-faqs`, {
        params
      })
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/seo-faqs/${id}`)
    )
  }

  getByIds = async (ids) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/seo-faqs/by-ids/${ids}`),
      200,
      true
    )
  }

  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/seo-faqs/${id}`, data)
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/seo-faqs/${id}`),
      204
    )
  }
}

export default new SeoFaqsActions()
