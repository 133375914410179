<template>
  <div id="app">
    <transition name="page">
      <component :is="layout">
        <router-view />
      </component>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AppLayout',
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  }
}
</script>
