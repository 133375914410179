import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class FAQsActions {

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post('/v2/admin/faqs', data),
      201
    )
  }

  getByID = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/faqs/${id}`)
    )
  }

  updateByID = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/faqs/${id}`, data)
    )
  }

  update = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/faqs`, data), 
      204
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/faqs/${id}`),
      204
    )
  }
}

export default new FAQsActions()
