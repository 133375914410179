import ApiConnector from '../connector'
import { camelToSnakeCase } from '../../../helpers/convertString'

class ClientRequesitesActions {
  getList = async (query) => {
    const result = {
      value: false,
      error: null
    }

    const params = {
      page: query.page || 1,
      limit: query.limit || 20
    }
    if (query.search) {
      params.search = query.search
    }
    if (query.orderBy) {
      params.order_by = camelToSnakeCase(query.orderBy)
    }
    if (query.orderDir) {
      params.order_dir = query.orderDir
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/client-requesites`,
        { params }
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  getByDaData = async (query, limit = 1) => {
    const result = {
      value: false,
      error: null
    }
    const params = { query, limit }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/client-requesites/by-inn`,
        {
          params
        }
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again later')
    }

    return result
  }

  getById = async (id) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/client-requesites/${id}`
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  update = async (id, data) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.put(
        `/v1/admin/client-requesites/${id}`,
        data
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  getSearchList = async (query) => {
    const result = {
      value: false,
      error: null
    }

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/client-requesites`,
        { params: { limit: query.limit, search: query.search } }
      )
      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new ClientRequesitesActions()
