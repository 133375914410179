import ApiConnector from '../connector'

class ImagesActions {
    sendPhoto = async (file) => {
        const result = {
            value: false,
            error: null,
        }

        const data = new FormData();
        data.append("file", file);

        try {
            const response = await ApiConnector.connector.post('/v1/img', data)

            if (response.status === 200) {
                result.value = response.data.result
            } else {
                result.error = new Error(`API error, status: ${response.status}`)
            }
        } catch (e) {
            result.error = new Error('Network error, try again late')
        }

        return result
    }
}

export default new ImagesActions()
