import delivery from '@/delivery'
import router from '@/router'
import { formatDateTime } from '@/helpers'

export default {
  state: () => ({
    page: 1,
    limit: 20,
    list: [],
    count: 0,
    messageList: [],
    messagesCount: 0,
    messagesPage: 1,
    messagesLimit: 50,
    socketStatus: false,
    dealer: {}
  }),

  getters: {
    pageChatsCount(state) {
      return Math.ceil(state.count / state.limit)
    },
    messageList: (state) => {
      return state.messageList.map((item) => {
        return {
          outMessage: !item.isWrittenManager,
          text: item.text,
          attachment: item.attachment,
          time: formatDateTime(item.createdAt),
          avatar: item.creatorImage,
          name: item.creatorName,
          markedAsRead: item.isReadDealer
        }
      })
    },
    unreadMessages: (state) => {
      return state.messageList.filter((item) => !item.isReadManager)
    },
    unreadCoordinatorMessages: (state) => {
      return state.messageList.filter((item) => !item.isReadCoordinator)
    }
  },

  mutations: {
    setChatsList(state, list) {
      state.list = list
    },
    setChatsCount(state, count) {
      state.count = count
    },
    setChatsPage(state, page) {
      state.page = page
    },
    setChatsPageLimit(state, limit) {
      state.limit = limit
    },
    setMessages(state, messages) {
      state.messageList = messages
    },
    setMessagesCount(state, count) {
      state.messagesCount = count
    },
    setMessagesDealer(state, dealer) {
      state.dealer = dealer
    },
    setMessagesPage(state, page) {
      state.messagesPage = page
    },
    addMessage(state, message) {
      if (
        state.messageList[0] &&
        state.messageList[0].dealerAccountId === message.dealerAccountId
      ) {
        let oldMessage
        const list = state.messageList.map((item) => {
          oldMessage = oldMessage || item.id === message.id
          return item.id === message.id ? message : item
        })
        state.messageList = oldMessage ? list : [...state.messageList, message]
      }
    },
    addMessages(state, messages) {
      state.messageList = [...messages, ...state.messageList]
    },
    setSocketStatus(state, socketStatus) {
      state.socketStatus = socketStatus
    }
  },

  actions: {
    async getChats({ commit, state }) {
      let result = { value: null, error: null }
      if (router.currentRoute.name === 'sellersChats') {
        result = await delivery.AddwineCore.ChatsActions.getList(
          state.page,
          state.limit
        )
      }
      if (router.currentRoute.name === 'coordinatorChats') {
        result = await delivery.AddwineCore.ChatsActions.getCoordinatorList(
          state.page,
          state.limit
        )
      }
      if (result.error) return
      if (result.value) {
        commit('setChatsList', result.value.data)
        commit('setChatsCount', result.value.meta.count)
      }
    },
    async getMessageList({ commit, state }, dealerAccountId) {
      const result = await delivery.AddwineCore.ChatsActions.getMessageList(
        1,
        state.messagesLimit,
        dealerAccountId
      )

      if (result.error) {
        this.error = result.error
      } else {
        commit('setMessagesPage', 1)
        commit('setMessages', result.value.data)
        commit('setMessagesCount', result.value.meta.count)
        commit('setMessagesDealer', result.value.dealerAccount)
      }
    },
    async getMoreMessageList({ state, commit }, dealerAccountId) {
      if (state.messagesLimit * state.messagesPage < state.messagesCount) {
        commit('setMessagesPage', ++state.messagesPage)
        const result = await delivery.AddwineCore.ChatsActions.getMessageList(
          state.messagesPage,
          state.messagesLimit,
          dealerAccountId
        )

        if (result.error) {
          return false
        } else {
          commit('addMessages', result.value.data)
          return true
        }
      } else {
        return false
      }
    },
    async initWsChat({ state }) {
      return !state.socketStatus
        ? await delivery.AddwineCore.WsChatActions.open()
        : state.socketStatus
    }
  }
}
