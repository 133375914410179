import ApiConnector from '../connector'

class AttributeValuesActions {
  delete = async (data) => {
    const result = {
      value: false,
      error: null
    }

    const params = {
      product_id: data.productId,
      attribute_id: data.attributeId
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v2/admin/attribute-values`,
        { params }
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new AttributeValuesActions()
