import axios from 'axios'
import store from '@/store'

class ApiConnector {
  connector

  constructor() {
    this.connector = axios.create({
      baseURL: '/api/payment_service'
    })

    this.connector.interceptors.request.use((config) => {
      const newConfig = { ...config }
      if (store && store.getters.isAuth) {
        newConfig.headers.Authorization = `Bearer ${store.state.auth.accessToken}`
      }

      return newConfig
    })

    this.connector.interceptors.response.use(
      function(response) {
        return response
      },
      function(error) {
        const IGNORED_PATHS = [
          '/api/v1/admin/auth/sign-in',
          '/api/v1/admin/auth/refresh-token'
        ]

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          const isIgnored = IGNORED_PATHS.some((path) =>
            error.config.url.includes(path)
          )

          const statusCode = error.response ? error.response.status : -1

          if ((statusCode === 401 || statusCode === 422) && !isIgnored) {
            const textCode = error.response.data?.errors && error.response.data?.errors[0] || {}
            const refreshToken = store.state.auth.refreshToken
            if (textCode === 'TOKEN_EXPIRED' && refreshToken) {
              if (
                Object.prototype.hasOwnProperty.call(
                  error.config,
                  'retryAttempts'
                )
              ) {
                return await store.dispatch('logout')
              } else {
                const config = { retryAttempts: 1, ...error.config }
                try {
                  await store.dispatch('refresh')
                  config.headers.Authorization = `Bearer ${store.state.auth.accessToken}`
                  return resolve(store.dispatch('request', config))
                } catch (e) {
                  return await store.dispatch('logout')
                }
              }
            } else if (textCode === 'TOKEN_INVALID') {
              return await store.dispatch('logout')
            }
          }

          return reject(error)
        })
      }
    )
  }
}

export default new ApiConnector()
