import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class OrderStatesActions {
  synchronize = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/orders/states/synchronization`)
    )
  }
  getList = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get('/v2/admin/orders/states')
    )
  }
  remove = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/orders/states/${id}`),
      204
    )
  }
  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.patch(`/v2/admin/orders/states/${id}`, data),
      200
    )
  }
}

export default new OrderStatesActions()
