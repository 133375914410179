import ApiConnector from '../connector'

class ProductCategoriesActions {
  addByFilters = async (categoryId, query, filters) => {
    const result = {
      value: false,
      error: null
    }

    const params = { category_id: categoryId }

    if (query?.excludedCategories?.length)
      params.excludedCategories = query.excludedCategories.join(',')

    if (query?.search)
      params.search = query.search.trim()

    if (filters?.isAvailable)
      params.is_available = filters.isAvailable

    if (filters?.isActive)
      params.is_active = filters.isActive

    if (filters?.assortments?.length)
      params.assortments = filters.assortments.join(',')

    if (filters?.brands?.length)
      params.brands = filters.brands.join(',')

    if (filters?.categories?.length)
      params.categories = filters.categories.join(',')

    try {
      const response = await ApiConnector.connector.post(
        `/v2/admin/product-categories/by-filters`,
        {},
        { params }
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  deleteByFilters = async (categoryId, filters) => {
    const result = {
      value: false,
      error: null
    }

    const params = { category_id: categoryId }

    if (filters.isAvailable) {
      params.is_available = filters.isAvailable
    }

    if (filters.isActive) {
      params.is_active = filters.isActive
    }

    if (filters.search) {
      params.search = filters.search.trim()
    }

    if (filters?.assortments?.length)
      params.assortments =
        filters.assortments.map((i) => i.value).join(',') || undefined

    if (filters?.brands?.length)
      params.brands = filters.brands.map((i) => i.value).join(',') || undefined

    if (filters?.categories?.length)
      params.categories =
        filters.categories.map((i) => i.value).join(',') || undefined

    try {
      const response = await ApiConnector.connector.delete(
        `/v2/admin/product-categories/by-filters`,
        { params }
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  delete = async (data) => {
    const result = {
      value: false,
      error: null
    }

    const params = {
      category_id: data.categoryId,
      product_ids: data.productIds.join(',')
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v2/admin/product-categories`,
        { params }
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new ProductCategoriesActions()
