import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'

class GeneralOrdersActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      search: query?.search || null,
      order_by: query?.orderBy || 'created_at',
      order_dir: query?.orderDir || 'desc',
      user_id: query?.userID
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/orders`, {
        params
      })
    )
  }

  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/orders/${id}`)
    )
  }

  updateOrder = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.patch(`/v2/admin/orders/${id}`, data)
    )
  }

  createOrder = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/orders`, data),
      201
    )
  }
  createOrderMS = async (id)=>{
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/orders/${id}/ms`),
      204
    )
  }
}

export default new GeneralOrdersActions()
