import connector from './connector'
import CategoriesActions from './CategoriesActions'
import ProductsActions from './ProductsActions'
import ProductCategoriesActions from './ProductCategoriesActions'
import BrandsActions from './BrandsActions'
import OrdersActions from './OrdersActions'
import SettingsActions from './SettingsActions'
import TagsActions from './TagsActions'
import SolutionsActions from './SolutionsActions'
import ArticleCategoriesActions from './ArticleCategoriesActions'
import AssortmentsActions from './AssortmentsActions'
import ArticlesActions from './ArticlesActions'
import SeoTemplatesActions from '@/delivery/AddeventServiceCore/SeoTemplatesActions'
class AddeventServiceCore {
  connector = connector
  CategoriesActions = CategoriesActions
  ProductsActions = ProductsActions
  ProductCategoriesActions = ProductCategoriesActions
  BrandsActions = BrandsActions
  OrdersActions = OrdersActions
  SettingsActions = SettingsActions
  TagsActions = TagsActions
  SolutionsActions = SolutionsActions
  AssortmentsActions = AssortmentsActions
  ArticleCategoriesActions = ArticleCategoriesActions
  ArticlesActions = ArticlesActions
  SeoTemplatesActions = SeoTemplatesActions
}

export default new AddeventServiceCore()
