export default function hasRole(roles) {
  return ({ store, next }) => {
    if (!roles?.length) {
      return next()
    }
    if (!roles.includes(store.state.auth.role)) {
      return next({ name: 'Home' })
    }
    return next()
  }
}
