import connector from './connector'
import CategoriesActions from './CategoriesActions'
import GroupsActions from './GroupsActions'
import AttributesActions from './AttributesActions'
import RankedAttributesActions from './RankedAttributesActions'
import AttributeOptionsActions from './AttributeOptionsActions'
import AttributeValuesActions from './AttributeValuesActions'
import AttributeValueOptionsActions from './AttributeValueOptionsActions'
import GroupAttributesActions from './GroupAttributesActions'
import BrandsActions from './BrandsActions'
import ProductsActions from './ProductsActions'
import ProductCategoriesActions from './ProductCategoriesActions'
import ProductAssortmentsActions from './ProductAssortmentsActions'
import AssortmentsActions from './AssortmentsActions'
import ImagesActions from './ImagesActions'
import SynchronizationActions from './SynchronizationActions'
import CatalogsActions from './CatalogsActions'

class ProductServiceCore {
  connector = connector
  CategoriesActions = CategoriesActions
  AssortmentsActions = AssortmentsActions
  GroupsActions = GroupsActions
  AttributesActions = AttributesActions
  RankedAttributesActions = RankedAttributesActions
  AttributeOptionsActions = AttributeOptionsActions
  AttributeValuesActions = AttributeValuesActions
  GroupAttributesActions = GroupAttributesActions
  BrandsActions = BrandsActions
  ProductsActions = ProductsActions
  ProductCategoriesActions = ProductCategoriesActions
  ProductAssortmentsActions = ProductAssortmentsActions
  AttributeValueOptionsActions = AttributeValueOptionsActions
  ImagesActions = ImagesActions
  SynchronizationActions = SynchronizationActions

  CatalogsActions = CatalogsActions;
}

export default new ProductServiceCore()
