import { getCatalogParams } from '@/helpers/delivery/sellers'
import ApiConnector from '../connector'

class ProductsActions {
  getFilterOptions = async (
    supplierId = null,
    exceptDealerCatalogId = null,
  ) => {
    const result = {
      value: false,
      error: null,
    }

    const params = {}

    if (supplierId !== null) params.supplier_id = supplierId

    if (exceptDealerCatalogId !== null)
      params.except_dealer_catalog_id = exceptDealerCatalogId

    try {
      const response = await ApiConnector.connector.get(
        `/v1/admin/products/filter-options`,
        { params },
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  getList = async (query) => {
    const result = {
      value: false,
      error: null,
    }

    const params = getCatalogParams(query)

    try {
      const response = await ApiConnector.connector.get('/v1/admin/products', {
        params,
      })

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  getByVendorCode = async (vendorCode) => {
    const result = {
      value: false,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.get(
        '/v1/admin/products/by-vendor-code',
        {
          params: {
            code: vendorCode,
          },
        },
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  attachSupplier = async (productId, supplierId) => {
    const result = {
      value: false,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.put(
        `/v1/admin/products/${productId}/supplier/${supplierId}`,
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  detachSupplier = async (id) => {
    const result = {
      value: false,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.delete(
        `/v1/admin/products/${id}/supplier`,
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  attachSupplierToFilteredProducts = async (query, supplierId) => {
    const result = {
      value: false,
      error: null,
    }

    const params = getCatalogParams(query)

    try {
      const response = await ApiConnector.connector.put(
        `/v1/admin/products/supplier/${supplierId}`,
        null,
        { params },
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  detachSupplierFromFilteredProducts = async (query, supplierId) => {
    const result = {
      value: false,
      error: null,
    }

    const params = getCatalogParams(query)

    try {
      const response = await ApiConnector.connector.delete(
        `/v1/admin/products/supplier/${supplierId}`,
        { params },
      )

      if (response.status === 204) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  getSearchList = async (query) => {
    const result = {
      value: false,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.get(`/v1/admin/products`, {
        params: { limit: query.limit, search: query.search, page: query.page },
      })

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  updatePriority = async (id, data) => {
    const result = {
      value: false,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.put(
        `/v1/admin/products/${id}`,
        data,
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }

  getXLSX = async () => {
    const result = {
      value: false,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.get(
        '/v1/admin/products/xlsx',
        { responseType: 'blob' },
      )
      if (response.status === 200) {
        result.value = response.data
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again later')
    }

    return result
  }

  importXLSX = async (file) => {
    const result = {
      value: false,
      error: null,
    }

    try {
      const data = new FormData()
      data.append('file', file)

      const response = await ApiConnector.connector.post(
        '/v1/admin/products/xlsx',
        data,
      )

      if (response.status === 200) {
        result.value = response.data.result
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (e) {
      result.error = new Error('Network error, try again late')
    }

    return result
  }
}

export default new ProductsActions()
