import delivery from '@/delivery'

export default {
  state: () => ({
    categories: [],
    assortments: [],
    brands: [],
    suppliers: []
  }),
  mutations: {
    setFiltersOptions(state, data) {
      state.brands = data.brands
      state.assortments = data.assortments
      state.categories = data.categories
      state.suppliers = data.suppliers
    }
  },
  actions: {
    getFiltersOptions({ commit, state }) {
      if (
        !state.categories.length &&
        !state.assortments.length &&
        !state.brands.length &&
        !state.suppliers.length
      ) {
        Promise.all([
          delivery.ProductsCore.CategoriesActions.getTree(),
          delivery.ProductsCore.AssortmentsActions.getList({ limit: 100 }),
          delivery.ProductsCore.BrandsActions.getList({ limit: 100 }),
          delivery.ProductsCore.ProductsActions.getProductsSuppliers({
            limit: 30
          })
        ]).then((results) => {
          const filtersOptions = {}
          const [categoriesData, assortmentsData, brandsData, suppliersData] =
            results

          if (!categoriesData.error && categoriesData.value.children?.length) {
            filtersOptions.categories = categoriesData.value.children
          }

          if (!assortmentsData.error && assortmentsData.value?.data?.length) {
            filtersOptions.assortments = assortmentsData.value?.data
          }

          if (!brandsData.error && brandsData.value?.data?.length) {
            filtersOptions.brands = brandsData.value?.data
          }

          if (!suppliersData.error && suppliersData.value?.data?.length) {
            filtersOptions.suppliers = {
              ...suppliersData.value,
              meta: { page: 1, count: suppliersData.value.meta.count }
            }
          }

          commit('setFiltersOptions', filtersOptions)
        })
      }
    }
  }
}
