import ApiConnector from '../connector'
import { apiRequestWrapper } from '@/helpers/delivery'
import { SORTING_TYPES, HTTP_STATUSES } from '@/constants/common'

class SeoTemplatesActions {
  getList = async (query) => {
    const params = {
      page: query?.page || 1,
      limit: query?.limit || 20,
      order_by: query?.order_by ?? 'created_at',
      order_dir: query?.order_dir ?? SORTING_TYPES.DESC,
    }

    if (query?.type) {
      params.type = query.type
    }

    if (query?.entity) {
      params.entity = query.entity
    }

    if (query?.search) {
      params.search = query.search
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/seos`, {
        params,
      }),
    )
  }
  getOne = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v2/admin/seos/${id}`),
    )
  }

  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v2/admin/seos`, data),
      HTTP_STATUSES.CREATED,
    )
  }

  update = async (data, id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v2/admin/seos/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v2/admin/seos/${id}`),
      HTTP_STATUSES.NO_CONTENT,
      true,
    )
  }
}

export default new SeoTemplatesActions()
